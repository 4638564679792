import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    shop_info_data: {},
    security_token: '',
    settingData: {}
};

const shop_info = createSlice({
    name: 'shop_info',
    initialState,
    reducers: {
        setShopData(state, action) {
            state.shop_info_data = action.payload.shop_info;
            state.security_token = action.payload.token;
            state.settingData = action.payload.settings;
        }
    }
});

export const { setShopData } = shop_info.actions;
export default shop_info.reducer;
