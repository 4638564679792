import React, { createElement, useState } from 'react';
import { discountCodeRemoveApi, discountDataApi, getSpecificOffers } from '../helper/axios';
import { useDispatch, useSelector } from 'react-redux';
import { CART, discountsPrice, extraFeeProduct, pricetable, subTotalDiscountValue } from '../redux/redux_slices/cart.slice';
import { useEffect } from 'react';
import { LocalStorageGetData, ShopifyformatMoney, convertStringToHTML, discountedProduct, getAllDomElements, isRealValue } from '../helper/Commanfun';
import axios from 'axios';

function Cart() {
  const [sideCartPopup, setSideCartPopup] = useState(true);
  let dispatch = useDispatch();
  const { product_data, discounts, price_data, subtotal_discount_value } = useSelector((state) => state.cartInfo);
  const { security_token, settingData, shop_info_data } = useSelector((state) => state.shopInfo);
  let productData = product_data?.items;
  let DeveloperOptionData = settingData?.developer_option;
  let orderType = JSON.parse(settingData?.order_setting);
  let dataParse = DeveloperOptionData ? JSON.parse(DeveloperOptionData) : '';
  const drawerSelector = document.querySelector('.drawer.animate.active');
  let fees_and_free_shipping = settingData?.fees_and_free_shipping_status && settingData?.fees_and_free_shipping_status == 1 && settingData?.fees_and_free_shipping ? JSON.parse(settingData?.fees_and_free_shipping) : '';
  let extra_fee_product_data = settingData?.fees_and_free_shipping_status && settingData?.fees_and_free_shipping_status == 1 && isRealValue(settingData?.fees_product) ? JSON.parse(settingData?.fees_product) : null;
  let qtySelector = document.querySelectorAll(['.cart-item__quantity-wrapper.quantity-popover-wrapper', "input[name='updates[]']"]);
  useEffect(() => {
    (async () => {
      try {
        let headers = {
          'Content-Type': 'application/json',
          'authentication': `${security_token}`
        };
        if (orderType?.order_type == 2) {
          // let cookies = document.cookie.split(';');
          // for (let i = 0; i < cookies.length; i++) {
          //   let cookie = cookies[i].trim();
          //   console.log('----------->123');
          //   if (cookie.indexOf('discountId') === 0) {
          //     let discount_id = cookie.split('=')[1];
          //     document.cookie = 'discountId' + '=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;';
          //     console.log(discount_id);
          //     await discountCodeRemoveApi(security_token, discount_id, shop_info_data);
          //     await fetch(`http://${window.location.hostname}/checkout?discount=%27%27`);
          //     await axios.post('/cart/update.js', {}, { 'headers': headers });
          //     dispatch(CART());
          //   }
          // }
          let discountCodeIdGet = LocalStorageGetData('discountId');
          if (discountCodeIdGet && isRealValue(discountCodeIdGet)) {
            localStorage.removeItem("discountId");
            await discountCodeRemoveApi(security_token, discountCodeIdGet, shop_info_data);
            await fetch(`http://${window.location.hostname}/checkout?discount=%27%27`);
            await axios.post('/cart/update.js', {}, { 'headers': headers });
            dispatch(CART());
          }
        }
      } catch (error) {
        console.log('error---------', error);
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(CART());
  }, []);

  if (document.querySelectorAll('.sp-buy-wrap').length) {
    let buyBtn = document.querySelectorAll('.sp-buy-wrap');
    buyBtn.forEach((element, index) => {
      document.querySelectorAll('.sp-form-check-input')[index].checked = false;
      element.classList.remove('sp-active');
    });
  }
  if (sideCartPopup) {
    let drawerSelector = ['.header__icon.header__icon--cart', '.cart-flyout.redesigned.is-active'];
    if (dataParse?.mini_cart_selector.trim()) {
      drawerSelector.unshift(dataParse?.mini_cart_selector);
      drawerSelector = document.querySelector(drawerSelector);
    } else {
      drawerSelector = document.querySelector(drawerSelector);
    }
    drawerSelector && drawerSelector.addEventListener('click', function () {
      setSideCartPopup(false);
    });
  }
  useEffect(() => {
    (async () => {
      // /* try {
      //   let headers = {
      //     'Content-Type': 'application/json',
      //     'authentication': `${security_token}`
      //   };
      //   if (product_data && isRealValue(productData) && security_token) {
      //     let productIds = [];
      //     for (let index = 0; index < productData.length; index++) {
      //       const productsid = productData[index];
      //       if (!productIds.includes(productsid.product_id.toString())) {
      //         productIds.push(productsid.product_id.toString());
      //       }
      //     }
      //     let locationId = LocalStorageGetData('Location_id');
      //     locationId = locationId && isRealValue(locationId) && locationId;
      //     let res = await getSpecificOffers(productIds, headers, locationId);
      //     if (res && res.status == 'SUCCESS' && isRealValue(res)) {
      //       // setResponse(res.data);
      //       dispatch(pricetable(res.data));
      //     }
      //   }
      // } catch (error) {
      //   console.log('error=========>', error);
      // } */

      let res = {};
      if (meta.page.pageType === 'cart') {
        res = await discountDataApi(product_data, productData, security_token);
      } else if (!sideCartPopup) {
        res = await discountDataApi(product_data, productData, security_token);
      }
      if (res && res.status == 'SUCCESS' && isRealValue(res)) {
        // setResponse(res.data);
        dispatch(pricetable(res.data));
      }
    })();
  }, [product_data, security_token, sideCartPopup]);

  useEffect(() => {
    let customer = [];
    let logincus = [];
    let nonlogin = [];

    const CartItemClass = ['.cart-item', 'tr.cart__row', '.cart__item', '.cart-table .item-row', '.products-cart', '.shopItem', '[data-cart-item]', '.line-item', 'tr[data-hulkapps-lineitem]'];
    let TotalPriceClass = ['.sp-cart-item-price', '.cart-item__price-wrapper .price', '.cart__final-price', '.cart__price', '.line-item__line-price', '.hulkapps-cart-item-line-price', '.cart__items__total'];
    let specificStoreClass = ['.sp-cart-item-line-price', 'cart__final-price.text-right.small--hide'];
    if (dataParse?.count_line_item_selector && dataParse?.count_line_item_selector.trim().length != 0) {
      if (!(/^(#\d|\.\d)/.test(dataParse?.count_line_item_selector))) {
        CartItemClass.unshift(dataParse?.count_line_item_selector);
      }
    }
    if (dataParse?.line_item_price_selector && dataParse?.line_item_price_selector.trim().length != 0) {
      if (!(/^(#\d|\.\d)/.test(dataParse?.line_item_price_selector))) {
        TotalPriceClass.unshift(dataParse?.line_item_price_selector);
      }
    }
    if (document.querySelectorAll(specificStoreClass).length) {
      let perItemPriceClass = TotalPriceClass;
      TotalPriceClass = document.querySelectorAll(TotalPriceClass).length && specificStoreClass;
      specificStoreClass = perItemPriceClass;
    } else {
      specificStoreClass = [];
    }
    let cartItemList = CartItemClass ? getAllDomElements(CartItemClass, []) : '';
    // cartItemList = !sideCartPopup && drawerSelector ? [...cartItemList].reverse() : cartItemList;
    Object.keys(price_data).length && Object.keys(price_data).map((data) => {
      let resData = price_data[data];
      if (isRealValue(resData)) {
        resData?.map((item) => {
          let exclusive_Inclusive = item.customer_allow_type == 1 ? !(item.selected_user_value?.includes(meta?.page?.customerId?.toString())) : item.selected_user_value?.includes(meta?.page?.customerId?.toString());
          if (item.selected_user_value && exclusive_Inclusive && item.selected_user_type == 2) {
            customer.push(item);
          } else if (item.selected_user_type == 1) {
            logincus.push(item);
          } else if (item.selected_user_type == 0) {
            nonlogin.push(item);
          } else if (item.selected_user_type === null) {
            customer.push(item);
          }
        });
      }
    });

    if (meta.page.customerId) {
      if ((customer.length || logincus.length ||  isRealValue(fees_and_free_shipping?.item)) && TotalPriceClass && isRealValue(productData)) {
        let variantid = [];
        if (customer.length && logincus.length) {
          let cusDiscountData = [...logincus];
          for (let index = 0; index < customer.length; index++) {
            let SpecificloginCus = customer[index];
            for (let i = 0; i < logincus.length; i++) {
              const element = logincus[i];
              if (Object.keys(SpecificloginCus).toString() == Object.keys(element).toString()) {
                logincus.splice(i, 1);
              }
            }
          }
          variantid = [...customer, ...cusDiscountData];
        } else if (customer.length) {
          variantid = customer;
        } else {
          variantid = logincus;
        }
        variantid.sort((a, b) => a.priority - b.priority);
        // productData = !sideCartPopup && drawerSelector ? [...productData].reverse() : productData;
        let loginNologinWiseProduct = discountedProduct(productData, variantid, TotalPriceClass, cartItemList, specificStoreClass, product_data?.total_price / 100, orderType?.order_type, orderType?.both_discount_applied,security_token, orderType?.apply_discount_type,  extra_fee_product_data, fees_and_free_shipping, product_data?.item_count, qtySelector, settingData?.fees_and_free_shipping_status);
        dispatch(discountsPrice(loginNologinWiseProduct.Discount));
        dispatch(extraFeeProduct(loginNologinWiseProduct.extraFee));
      }
    } else if ((nonlogin.length > 0 || isRealValue(fees_and_free_shipping?.item)) && TotalPriceClass && isRealValue(productData)) {
      nonlogin.sort((a, b) => a.priority - b.priority);
      // productData = !sideCartPopup && drawerSelector ? [...productData].reverse() : productData;
      let loginNologinWiseProduct = discountedProduct(productData, nonlogin, TotalPriceClass, cartItemList, specificStoreClass, product_data?.total_price / 100, orderType?.order_type, orderType?.both_discount_applied,security_token, orderType?.apply_discount_type,  extra_fee_product_data, fees_and_free_shipping, product_data?.item_count, qtySelector, settingData?.fees_and_free_shipping_status);
      dispatch(discountsPrice(loginNologinWiseProduct.Discount));
      dispatch(extraFeeProduct(loginNologinWiseProduct.extraFee));
    }
  }, [price_data, sideCartPopup]); // product_data dependence remove because useeffect render 2 time and extra fees product add and remove api call 2 times.

  useEffect(() => {
    let priceClass = ['.cart-subtotal__price', '#CartSubtotal', '.large--one-third .cart__subtotal', '.cart__subtotal-container .cart__subtotal', '.large-up--text-right .cart__subtotal.h3', '.cart__totals .cart__subtotal-wrapper .cart__subtotal', '.wcp-original-cart-total', '.cart__footer .totals__subtotal-value', '.sf-cart-subtotal__price', '.cart__subtotal .h5.cart__subtotal-price', '.totals__total-value', '.sp-cart-original-total', '.totals-value .totals__subtotal-value', '.items_subtotal', '.order-total [data-hulkapps-cart-total]', 'span[data-cart-final]'];
    if (dataParse?.subtotal_selector && dataParse?.subtotal_selector.trim().length != 0) {
      if (!(/^(#\d|\.\d)/.test(dataParse?.subtotal_selector))) {
       let customSelector = dataParse?.subtotal_selector.split(',');
       customSelector?.forEach((item) => {
        priceClass.unshift(item);
       });
      }
    }
    function subTotalDiscount(subtotal, subSelector, index, totalPrice) {
      let subTotalClass = document.querySelector(`.cart-sub-total-${index}`);
      if (subTotalClass != null) {
        subTotalClass.innerHTML = Shopify.shop === 'pumbaworkz.myshopify.com' ? `${subtotal} ${Shopify.currency.active}` : subtotal;
      } else {
        let spanTagCreate = document.createElement('span');
        spanTagCreate.className = `cart-sub-total-${index} sp-subtotal`;
        spanTagCreate.innerHTML = `${Shopify.shop === 'pumbaworkz.myshopify.com' ? subtotal + Shopify.currency.active : subtotal}`;
        subSelector && subSelector.after(spanTagCreate);
      }
      subSelector ? subSelector.innerHTML = ShopifyformatMoney(totalPrice, currencyWihoutFormat) : '';
      subSelector && subtotal ? subSelector.style.textDecoration = 'line-through' : '';
    }
    priceClass = document.querySelectorAll(priceClass);
    for (let index = 0; index < priceClass.length; index++) {
      const element = priceClass[index];
      let findValue = isRealValue(product_data) && product_data.items.find((item) => item.hasOwnProperty('selling_plan_allocation'));
      const originalSubTotal = findValue && isRealValue(findValue) && product_data?.items.reduce((accumulator, item) => {
        let shopifyDiscountCheck = orderType?.apply_discount_type != 0 ? !isRealValue(item?.line_level_discount_allocations) : true;
        if ((orderType?.order_type == 0 || orderType?.order_type == 1) && shopifyDiscountCheck) {
          return accumulator += item.selling_plan_allocation && discounts > 0 ? (item.selling_plan_allocation.compare_at_price * item.quantity) / 100 : orderType?.apply_discount_type === '0' ? item.original_line_price / 100 : item.final_line_price / 100;
        }
      }, 0);
      let totalPrice = originalSubTotal ? originalSubTotal : orderType?.apply_discount_type === '0' && product_data?.original_total_price ? product_data?.original_total_price / 100 : product_data?.items_subtotal_price != undefined && product_data?.items_subtotal_price / 100;
      if (totalPrice && discounts && discounts > 0) {
        let subtotalPrice = totalPrice >= discounts ? totalPrice - discounts : 0;
        if (subtotalPrice >= 0) {
          dispatch(subTotalDiscountValue(subtotalPrice));
          isRealValue(product_data?.cart_level_discount_applications) && orderType?.apply_discount_type !== '0' && product_data?.cart_level_discount_applications.forEach((item) => {
            let percentageCal = subtotalPrice ? item.value_type === 'percentage' ? orderType?.order_type != 2 ? (totalPrice - discounts) * Number(item.value) / 100 : (totalPrice) * Number(item.value) / 100 : item.total_allocated_amount / 100 : subtotalPrice;
            subtotalPrice = percentageCal ? subtotalPrice - percentageCal : percentageCal;
          });
          subtotalPrice = Math.sign(subtotalPrice) != -1 ? subtotalPrice : 0;
          let currencyWiseDiscount = ShopifyformatMoney(subtotalPrice, currencyWihoutFormat);
          subTotalDiscount(currencyWiseDiscount, element, index, totalPrice);
        }
      }
      else if (orderType?.order_type != 2 && discounts == 0 && findValue) {
        let currencyWiseDiscount = ShopifyformatMoney(totalPrice, currencyWihoutFormat);
        if (element) {
          element.innerHTML = currencyWiseDiscount;
          document.querySelector(`.cart-sub-total-${index}`) ? document.querySelector(`.cart-sub-total-${index}`).remove() : '';
          element.style.textDecoration = null;
        }
      }
      else {
        discounts == 0 && document.querySelector(`.cart-sub-total-${index}`) ? document.querySelector(`.cart-sub-total-${index}`).remove() : '';
        discounts == 0 && element ? element.style.textDecoration = null : '';
      }
    }

    // general discount tag amount changes
    let generalDiscountTagSelector = !sideCartPopup && drawerSelector ? document.querySelectorAll(['.cart-drawer__footer .discounts__discount.discounts__discount--end']) : document.querySelectorAll(['.cart__blocks .discounts__discount.discounts__discount--position']);
    if (isRealValue(product_data) && generalDiscountTagSelector && isRealValue(generalDiscountTagSelector) && orderType?.apply_discount_type !== '0' && orderType?.order_type != 2) {
      // let totalPrice = product_data.items_subtotal_price >= discounts ? (product_data.items_subtotal_price / 100) - discounts : 0;
        isRealValue(product_data?.cart_level_discount_applications) && product_data?.cart_level_discount_applications.forEach((item, i) => {
          let percentageCal = subtotal_discount_value && subtotal_discount_value * Number(item.value) / 100;
          if (item.value_type === 'percentage' && percentageCal) {
            let currencyWiseDiscount = ShopifyformatMoney(percentageCal, currencyWihoutFormat);
            let htmlElement = convertStringToHTML(currencyWiseDiscount);
            generalDiscountTagSelector[i].lastChild.nodeValue = `${item.title} (-${htmlElement})`;
          }
        });
    }
  }, [product_data, discounts, subtotal_discount_value, sideCartPopup, drawerSelector]);

  let selector = (['.discounts.list-unstyled']);
  if (dataParse?.shopify_discount_tag_selector.trim()) {
    selector.unshift(dataParse?.shopify_discount_tag_selector);
    selector = document.querySelectorAll(selector);
  } else {
    selector = document.querySelectorAll(selector);
  }
  if (orderType?.apply_discount_type === '0' && selector && selector.length) {
    selector.forEach((item) => {
      item.remove();
    });
  }

  window.getFetchCall = () => {
    // if (window.location.href.split('/').indexOf('cart') != -1) {
    setTimeout(() => {
      dispatch(CART());
    }, 500);
    // }
  };

  return (
    <div>

    </div>
  );
}

export default Cart;