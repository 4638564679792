/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { getSpecificOffers, getCollectionsPrice, getLocationData, collectionPageError } from '../helper/axios';
import { Table } from './Table';
import { useDispatch, useSelector } from 'react-redux';
import { pricetable } from '../redux/redux_slices/cart.slice';
import { LocalStorageGetData, LocalStorageSetData, ShopifyformatMoney, collectionPageDiscountShow, getAllDomElements, isRealValue } from '../helper/Commanfun';
import { setShopData } from '../redux/redux_slices/shop.slice';
import Volume from './Volume';
import Wholesale from './Wholesale';
import Collections from './Collections';
import B2B from './B2B';

function PriceTable() {
  const [response, setResponse] = useState({});
  const [collData, setCollData] = useState({});
  const [priceqty, setpriceqty] = useState();
  const [location, setLocation] = useState();
  const [searchData, setSearchData] = useState();
  const { security_token } = useSelector((state) => state.shopInfo);
  const dispatch = useDispatch();
  let responseData = [];
  useEffect(() => {
    (async () => {
      try {
        let headers = {
          'Content-Type': 'application/json',
          'authentication': `${security_token}`
        };
        let locationId = LocalStorageGetData('Location_id');
        if (meta && meta.page && meta.page.pageType == 'product' && meta.product) {
          let variantsArr = [];
          if (meta.product.variants.length) {
            let variants = meta.product.variants;
            for (let i = 0; i < variants.length; i++) {
              const id = variants[i].id;
              variantsArr.push(id);
            }
          }
          let productIds = [`${meta.product.id}`];
          locationId = locationId && isRealValue(locationId) && locationId;
          let res = await getSpecificOffers(productIds, security_token,locationId);
          if (isRealValue(res)) {
            setResponse(res.data);
            dispatch(pricetable(res.data));
          }
        } else if (meta && meta.page && ((meta.page.pageType == 'collection' && meta.products) || meta.page.pageType == 'searchresults')) {
          let productIds = {};
          let spClassSelector = document.querySelectorAll('.sp-search');
          if (meta?.products?.length) {
            for (let j = 0; j < meta.products.length; j++) {
              let variantsArr = {};
              const element = meta.products[j];
              let variants = element.variants;
              for (let i = 0; i < variants.length; i++) {
                const variant = variants[i];
                variantsArr[variant.id] = variant.price / 100;
              }
              productIds[`${Number(element.id)}`] = variantsArr;
            }
            if (isRealValue(productIds)) {
              locationId = locationId && isRealValue(locationId) && locationId;
              let res = await getCollectionsPrice(productIds, security_token, locationId);
              if (isRealValue(res)) {
                setCollData(res.data);
                //   dispatch(pricetable(res.data));
              }
            }
          } else if (isRealValue(spClassSelector)) {
            const searchFun = (target, source) => {
              for (const key of Object.keys(source)) {
                if (source[key] instanceof Object && target[key]) {
                  searchFun(target[key], source[key]);
                } else {
                  target[key] = source[key];
                }
              }
              return target;
            };
            const searchData = {};
            spClassSelector.forEach(input => {
              let data = input.getAttribute('sp-search');
              data = JSON.parse(data);
              searchFun(searchData, data);
            });
            if (isRealValue(searchData)) {
              const productData = { products: [] };
              for (const productId in searchData) {
                const variants = Object.entries(searchData[productId]).map(([variantId, price]) => ({
                  id: variantId,
                  price: price
                }));
                productData.products.push({
                  id: productId,
                  variants: variants
                });
              }
              setSearchData(productData);
              locationId = locationId && isRealValue(locationId) && locationId;
              let res = await getCollectionsPrice(searchData, security_token, locationId);
              if (isRealValue(res)) {
                setCollData(res.data);
                //   dispatch(pricetable(res.data));
              }
            }
          }
        }

        if (meta?.page?.customerId) {
          let res = await getLocationData(security_token);
          if (res && res.status == 'SUCCESS') {
            setLocation(res.data);
          }
        }

        if (!(meta?.page?.customerId) && locationId && isRealValue(locationId)) {
          LocalStorageSetData('Location_id');
          LocalStorageSetData('Address');
        }
      } catch (error) {
        console.log('error=========>', error);
      }
    })();
  }, []);

  if (isRealValue(response) && response) {
    for (const property in response) {
      for (let index = 0; index < response[property].length; index++) {
        responseData.push(response[property][index]);
      }
    }
    responseData.sort((a, b) => a.priority - b.priority);
  }
  return (
    <>
      {/* {isRealValue(response) && response[0] ? <Volume props={response[0]} />: <Wholesale />} */}
      {isRealValue(responseData) && responseData && <Volume props={responseData} />}
      {isRealValue(collData) && <Collections props={collData} searchData={searchData} />}
      {location && isRealValue(location) && <B2B props={location} />}
    </>
  );
}

export default PriceTable;
