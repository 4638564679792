import React, { useEffect, useState } from 'react';
import './Table.css';
import SelectOptionData from '../helper/CountryData';
import { regDataApi, regFormApi } from '../helper/axios';
import { useSelector } from 'react-redux';
import { decryptData, isRealValue } from '../helper/Commanfun';

function RegistrationForm() {
    const { security_token } = useSelector((state) => state.shopInfo);
    const [data, setdata] = useState([]);
    const [addressField, setAddressField] = useState([{ address1: '', city: '', country: '', postal_code: '', province: '' }]);
    const [customerDetailField, setCustomerDetailField] = useState([]);
    const [openIndex, setOpenIndex] = useState(0);
    const [errors, setErrors] = useState([]);
    const [emailErrors, setEmailErrors] = useState([]);
    const [mainContact, setMainContact] = useState([{ name: '', email: '' }]);
    const [companyName, setCompanyName] = useState([{ company_name: '' }]);
    const [mainNameError, setMainNameError] = useState([]);
    const [mainEmailError, setMainEmailError] = useState([]);
    const [companyError, setCompanyError] = useState([]);
    const [thankyou, setThankYou] = useState(0);
    const [errorBorder, setErrorBorder] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitClick, setSubmitClick] = useState(false);
    const queryParameters = new URLSearchParams(window.location.search);
    const key = queryParameters.get('key');
    useEffect(() => {
        (async () => {
            let res = await regDataApi(`/registrationFormView?key=${key}`);
            if (res && res.data) {
                setdata(res.data);
            }
        })();
    }, []);
    let regData = isRealValue(data) ? JSON.parse(data.registration_form) : null;
    const seletedCountry = SelectOptionData;
    let convertedData = seletedCountry.map(country => {
        let name = country.country_label;
        let value = country.country_value;
        let proviences = country.province ? JSON.parse(country.province) : '';
        let state = proviences ? proviences.map((prov) => (
            {
                state: prov.label,
                stateValue: prov.value
            }
        )) : [];
        return { name, value, state };
    });

    const handleAddField = () => {
        let anyFieldFilled = false;
        for (let index = 0; index < addressField.length; index++) {
            const element = addressField[index];
            for (const key in element) {
                if (element[key].trim() !== '') {
                    anyFieldFilled = true;
                    break;
                }
            }
            setErrorBorder(true);
            if (anyFieldFilled) {
                let provience = addressField.every((element,index) => document.querySelectorAll('.sp-form-field.sp-provience')[index].disabled ? 1 : element.province.trim().length > 0);
                if (addressField.every((element) => element.address1.trim().length > 0 && element.city.trim().length > 0 && element.country.trim().length > 0 && provience && element.postal_code.trim().length > 0)) {
                    setAddressField([...addressField, { address1: '', city: '', country: '', postal_code: '', province: '' }]);
                    setOpenIndex(addressField.length);
                }
            }
        }
    };

    const handleCountryChange = (index, event) => {
        const { value } = event.target;
        const newInputs = [...addressField];
        newInputs[index]['country'] = value;
        newInputs[index]['province'] = ' ';
        setAddressField(newInputs);
        if (!(document.querySelectorAll('.sp-form-field.sp-provience')[index].disabled)) {
            setErrorBorder(false);
        }
    };

    const handleChange = (index, event) => {
        const { name, value } = event.target;
        const newInputs = [...addressField];
        newInputs[index][name] = value;
        setAddressField(newInputs);
    };

    const handleCompanyName = (index, key, value) => {
        const Company_Name = [...companyName];
        Company_Name[index][key] = value;
        setCompanyName(Company_Name);
        // const nameRegex = /^[a-zA-Z\s]+$/;
        if (!value.trim()) {
            setCompanyError(prevErrors => [...prevErrors.slice(0, index), `${regData && decryptData(regData?.company_name_error)}`, ...prevErrors.slice(index + 1)]);
        } else {
            setCompanyError(prevErrors => [...prevErrors.slice(0, index), '', ...prevErrors.slice(index + 1)]);
        }
    };

    const handleCustomerChange = (index, key, value, booleanValue = false) => {
        if (booleanValue) {
            const mainContacts = [...mainContact];
            mainContacts[index][key] = value;
            setMainContact(mainContacts);
        } else {
            const newCustomerFiled = [...customerDetailField];
            newCustomerFiled[index][key] = value;
            setCustomerDetailField(newCustomerFiled);
        }
        if (key === 'name') {
            if (booleanValue) {
                if (!value.trim() != '') {
                    setMainNameError(prevErrors => [...prevErrors.slice(0, index), `${regData && decryptData(regData?.customer_name_not_null_name_error)}`, ...prevErrors.slice(index + 1)]);
                } else {
                    setMainNameError(prevErrors => [...prevErrors.slice(0, index), '', ...prevErrors.slice(index + 1)]);
                }
            } else {
                if (!value.trim()) {
                    setErrors(prevErrors => [...prevErrors.slice(0, index), `${regData && decryptData(regData?.customer_name_not_null_name_error)}`, ...prevErrors.slice(index + 1)]);
                } else {
                    setErrors(prevErrors => [...prevErrors.slice(0, index), '', ...prevErrors.slice(index + 1)]);
                }
            }
        }

        if (key === 'email') {
            if (booleanValue) {
                if (!value.trim() != '') {
                    setMainEmailError(prevErrors => [...prevErrors.slice(0, index), `${regData && decryptData(regData?.customer_email_not_null_name_error)}`, ...prevErrors.slice(index + 1)]);
                } else {
                    setMainEmailError(prevErrors => [...prevErrors.slice(0, index), '', ...prevErrors.slice(index + 1)]);
                }
            } else {
                if (!value.trim()) {
                    setEmailErrors(prevErrors => [...prevErrors.slice(0, index), `${regData && decryptData(regData?.customer_email_not_null_name_error)}`, ...prevErrors.slice(index + 1)]);
                } else {
                    setEmailErrors(prevErrors => [...prevErrors.slice(0, index), '', ...prevErrors.slice(index + 1)]);
                }
            }
        }
    };
    useEffect(() => {
        for (let index = 0; index < addressField.length; index++) {
            const element = addressField[index];
            if (element.address1.trim().length === 0 && element.city.trim().length === 0 && element.country.trim().length === 0 && element.province.trim().length === 0 && element.postal_code.trim().length === 0) {
                setErrorBorder(false);
            }

        }
    }, [addressField]);

    const handleEdit = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const handleRemoveField = (index) => {
        const newInputs = [...addressField];
        newInputs.splice(index, 1);
        setAddressField(newInputs);
        setOpenIndex(newInputs.length - 1);
    };

    const handleCustomerDetailField = () => {
        if (customerDetailField.every((field) => field.name.trim() && field.email.trim())) {
            setCustomerDetailField([...customerDetailField, { name: '', email: '' }]);
        } else {
            let nameError = regData ? decryptData(regData?.customer_name_not_null_name_error) : '';
            let emailError = regData ? decryptData(regData?.customer_email_not_null_name_error) : '';
            customerDetailField.map((item, index) => {
                if (!item.name.trim()) {
                    setErrors(prevErrors => [...prevErrors.slice(0, index), `${nameError}`, ...prevErrors.slice(index + 1)]);
                }
                if (!item.email.trim()) {
                    setEmailErrors(prevErrors => [...prevErrors.slice(0, index), `${emailError}`, ...prevErrors.slice(index + 1)]);
                }
            });
        }
    };

    const customerDetailFieldRemove = (index) => {
        setErrors(prevErrors => [...prevErrors.slice(0, index), ...prevErrors.slice(index + 1)]);
        setEmailErrors(prevErrors => [...prevErrors.slice(0, index), ...prevErrors.slice(index + 1)]);
        const CustomerFieldRemove = [...customerDetailField];
        CustomerFieldRemove.splice(index, 1);
        setCustomerDetailField(CustomerFieldRemove);
    };

    useEffect(() => {
        const errorElement = document.querySelectorAll(['.error', '#sp-error-border'])[0];
        if (submitClick && errorElement) {
            errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
            setSubmitClick(false);
        }
    }, [document.querySelectorAll(['.error', '#sp-error-border'])]);

    const handleSubmit = async (e) => {
        setSubmitClick(true);
        e.preventDefault();
        let nameValid = true;
        let emailValid = true;
        let companyValid = true;
        let flag;
        for (let index = 0; index < addressField.length; index++) {
            const element = addressField[index];
            let provience = addressField.every((element,index) => document.querySelectorAll('.sp-form-field.sp-provience')[index].disabled ? 1 : element.province.trim().length > 0);
            if (element.address1.trim() !== '' || element.country.trim() !== '' || element.city.trim() !== '' || element.postal_code.trim() !== '') {
                if (element.address1.trim() === '' || element.country.trim() === '' || element.city.trim() === '' || element.postal_code.trim() === '' || !provience) {
                     flag = 0;
                     setErrorBorder(true);
                } else {
                    flag = 1;
                }
            }
        }
        if (companyName.some(field => !field.company_name) || mainContact.some(field => !field.name || !field.email) || customerDetailField.some(field => !field.name || !field.email) || flag === 0) {
            let nameError = regData ? decryptData(regData?.customer_name_not_null_name_error) : '';
            let emailError = regData ? decryptData(regData?.customer_email_not_null_name_error) : '';
            let companyErrors = regData ? decryptData(regData?.company_name_error) : '';
            customerDetailField.forEach((field, index) => {
                if (!field.name.trim()) {
                    nameValid = false;
                    setErrors(prevErrors => [...prevErrors.slice(0, index), `${nameError}`, ...prevErrors.slice(index + 1)]);
                } else if (!/^[a-zA-Z\s]+$/.test(field.name)) {
                    nameValid = false;
                    setErrors(prevErrors => [...prevErrors.slice(0, index), `${nameError}`, ...prevErrors.slice(index + 1)]);
                } else {
                    setErrors(prevErrors => [...prevErrors.slice(0, index), '', ...prevErrors.slice(index + 1)]);
                }

                if (!field.email.trim()) {
                    emailValid = false;
                    setEmailErrors(prevErrors => [...prevErrors.slice(0, index), `${emailError}`, ...prevErrors.slice(index + 1)]);
                }
                else {
                    setEmailErrors(prevErrors => [...prevErrors.slice(0, index), '', ...prevErrors.slice(index + 1)]);
                }
            });

            if (!mainContact[0].name.trim()) {
                nameValid = false;
                setMainNameError([nameError]);
            } else if (!/^[a-zA-Z\s]+$/.test(mainContact[0].name)) {
                nameValid = false;
                setMainNameError([nameError]);
            } else {
                setMainNameError(['']);
            }

            if (!mainContact[0].email.trim()) {
                emailValid = false;
                setMainEmailError([emailError]);
            }
            else {
                setMainEmailError(['']);
            }

            if (!companyName[0].company_name.trim()) {
                companyValid = false;
                setCompanyError([companyErrors]);
            } else if (!/^[a-zA-Z\s]+$/.test(companyName[0].company_name)) {
                companyValid = false;
                setCompanyError([companyErrors]);
            } else {
                setCompanyError(['']);
            }
        } else {
            const filteredAddresses = addressField.filter((address) => address.address1.trim() != '' && address.country.trim() != '' && address.city.trim() != '' && address.postal_code.trim() != '');
            let payload = {
                url: key,
                method_name: 'registrationFormSubmit',
                name: mainContact[0].name,
                email: mainContact[0].email,
                company_name: companyName[0].company_name,
                customers: customerDetailField,
                shipping_details: filteredAddresses
            };
            setIsSubmitting(true);
            const res = await regFormApi(payload, security_token);
            if (res === 'Ok') {
                setThankYou(1);
            } else {
                setIsSubmitting(false);
                res && res.message.startsWith('Main') && setMainEmailError([decryptData(regData?.email_error)]);
                res && res.message.startsWith('"email"') && setMainEmailError([decryptData(regData?.wrong_email_error)]);
                res && res.message === 'Already Main user exits in company' && setMainEmailError([decryptData(regData?.company_withsame_email_alreadyExist_error)]);
                res && res.message === 'Already Main user exits in offer' && setMainEmailError([decryptData(regData?.customer_already_exits_in_another_offer)]);
                // res && res.message.includes('Already customer Exits') && setMainEmailError([decryptData(regData?.customer_already_exits_in_another_offer)]);
                customerDetailField.map((item, index) => {
                    if (res.message.includes(`[${index}]`)) {
                        setEmailErrors(prevErrors => [...prevErrors.slice(0, index), `${decryptData(regData?.wrong_email_error)}`, ...prevErrors.slice(index + 1)]);
                    }
                    res?.data?.forEach((data) => {
                        if (data.message === 'Customer already exits in company') {
                            if (data.email == item.email) {
                                setEmailErrors(prevErrors => [...prevErrors.slice(0, index), `${decryptData(regData?.company_withsame_email_alreadyExist_error)}`, ...prevErrors.slice(index + 1)]);
                            }
                        }
                        if (data.message === 'Customer already exits in offer') {
                            if (data.email == item.email) {
                                setEmailErrors(prevErrors => [...prevErrors.slice(0, index), `${decryptData(regData?.customer_already_exits_in_another_offer)}`, ...prevErrors.slice(index + 1)]);
                            }
                        }
                        if (data.message === 'Customer already submitted') {
                            if (data.email == item.email) {
                                setEmailErrors(prevErrors => [...prevErrors.slice(0, index), `${decryptData(regData?.email_error)}`, ...prevErrors.slice(index + 1)]);
                            }
                        }
                    });
                });
            }
        }
    };

    return (
        <>
            {
                key && isRealValue(data) ?
                    <div>
                        {
                            thankyou === 0 ?
                                <div className="sp-form-container">
                                    <h2 className="sp-page-title">{regData && decryptData(regData?.registration)}</h2>
                                    <p className="sp-page-description">{regData && decryptData(regData?.sub_title)}</p>
                                    <form onSubmit={handleSubmit}>
                                        <div className="sp-form-group">
                                            <label >{regData && decryptData(regData?.company_name)}*</label>
                                            <input type="text" className="sp-form-field" name='companyName' onChange={e => handleCompanyName(0, 'company_name', e.target.value)} />
                                            {companyError[0] && <p className='error'>{companyError[0]}</p>}
                                            <span className="sp-error">
                                                <span className="sp-icon">
                                                    <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16M9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0" fill="#D72C0D" /></svg>
                                                </span>
                                                <span className="sp-text">Please enter the company name</span>
                                            </span>
                                        </div>
                                        <div className="sp-shipping-details">
                                            <div className="sp-add-address">
                                                <h4>{regData && decryptData(regData?.shipping_details_caption)}</h4>
                                                <button onClick={handleAddField} type='button' className="btn sp-btn-outline">{regData && decryptData(regData?.add_address_btn)}</button>
                                            </div>
                                            <div className="sp-shipping-address-details sp-multi-address"> {/* Remove sp-multi-address className if address only one */}
                                                {addressField.map((input, index) => (
                                                    <div className={`sp-accordion ${openIndex === index ? 'sp-accordion-active' : ''}`} key={index}> {/* Add sp-accordion-active className if accordian is open */}
                                                        <div className="sp-add-address">
                                                            <h6>{regData && `${decryptData(regData?.shipping_address)} ${index + 1}`}</h6>
                                                            {
                                                                addressField.length !== 1 ?
                                                                    <div className="sp-action-btn-group">
                                                                        <a href="#" onClick={() => handleEdit(index)} className="sp-multi-accordion sp-action"><svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M16.155 4.789a2.695 2.695 0 0 0-3.81 0l-.599.599-.009-.01-1.06 1.06.008.01-5.88 5.88A2.75 2.75 0 0 0 4 14.274v1.922a.75.75 0 0 0 .75.75h1.922c.73 0 1.429-.29 1.944-.806l7.54-7.54a2.695 2.695 0 0 0 0-3.81zm-4.409 2.72-5.88 5.88a1.25 1.25 0 0 0-.366.884v1.172h1.172c.331 0 .65-.132.883-.366l5.88-5.88zm2.75.629.599-.6a1.194 1.194 0 1 0-1.69-1.688l-.598.599z" fill="#5C5F62" /></svg></a><span className="sp-action"> | </span>
                                                                        <a href="#" onClick={() => handleRemoveField(index)} className="sp-multi-accordion sp-action-delete sp-action"><svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.5 8.25a.75.75 0 0 1 .75.75v4.25a.75.75 0 1 1-1.5 0V9a.75.75 0 0 1 .75-.75M9.25 9a.75.75 0 0 0-1.5 0v4.25a.75.75 0 1 0 1.5 0z" fill="#5C5F62" /><path fillRule="evenodd" clipRule="evenodd" d="M7.25 5.25a2.75 2.75 0 1 1 5.5 0h3a.75.75 0 1 1 0 1.5H15v5.45c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C12.72 17 11.88 17 10.2 17h-.4c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C5 14.72 5 13.88 5 12.2V6.75h-.75a.75.75 0 0 1 0-1.5zm1.5 0a1.25 1.25 0 0 1 2.5 0zM6.5 6.75h7v5.45c0 .865-.001 1.423-.036 1.848-.033.408-.09.559-.128.633a1.5 1.5 0 0 1-.655.655c-.074.038-.225.095-.633.128-.425.035-.983.036-1.848.036h-.4c-.865 0-1.423-.001-1.848-.036-.408-.033-.559-.09-.633-.128a1.5 1.5 0 0 1-.656-.655c-.037-.074-.094-.225-.127-.633-.035-.425-.036-.983-.036-1.848z" fill="#5C5F62" /></svg></a>
                                                                    </div>
                                                                    : ''
                                                            }
                                                        </div>
                                                        <hr />
                                                        <div className="address-form">
                                                            <div className="sp-form-group w-100">
                                                                <label >{regData && decryptData(regData?.address)}</label>
                                                                <input type="text" id={`${errorBorder && input.address1.trim() === '' ? 'sp-error-border' : ''}`} className="sp-form-field" name="address1" value={input.address1} onChange={e => handleChange(index, e)} />
                                                                <span className="sp-error" />
                                                            </div>
                                                            <div className="sp-form-group-merge">
                                                                <div className="sp-form-group">
                                                                    <label >{regData && decryptData(regData?.country)}</label>
                                                                    <select className="sp-form-field"
                                                                        id={`${errorBorder && input.country.trim() === '' ? 'sp-error-border' : ''}`}
                                                                        name="country"
                                                                        value={input.country}
                                                                        onChange={e => handleCountryChange(index, e)}>
                                                                        <option value="">Select Country</option>
                                                                        {convertedData.map(country => (
                                                                            <option key={country.name} value={country.value}>{country.name}</option>
                                                                        ))}
                                                                    </select>
                                                                    <span className="sp-error" />
                                                                </div>
                                                                <div className="sp-form-group">
                                                                    <label >{regData && decryptData(regData?.province)}</label>
                                                                    <select className="sp-form-field sp-provience" name="province"
                                                                        id={`${errorBorder && document.querySelectorAll('.sp-form-field.sp-provience') && index === document.querySelectorAll('.sp-form-field.sp-provience').length - 1 && !(document.querySelectorAll('.sp-form-field.sp-provience')[index].disabled) && input.province.trim() === '' ? 'sp-error-border' : ''}`}
                                                                        value={input.province}
                                                                        onChange={e => handleChange(index, e)}
                                                                        disabled={!input.country || !convertedData.find(country => country.value === input.country)?.state.length}>
                                                                        <option value="">Select Province/State</option>
                                                                        {input.country &&
                                                                            convertedData.find(country => country.value === input.country)?.state.map(province => (
                                                                                <option key={province.state} value={province.stateValue}>{province.state}</option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    <span className="sp-error" />
                                                                </div>
                                                            </div>
                                                            <div className="sp-form-group-merge">
                                                                <div className="sp-form-group">
                                                                    <label >{regData && decryptData(regData?.city)}</label>
                                                                    <input type="text" id={`${errorBorder && input.city.trim() === '' ? 'sp-error-border' : ''}`} className="sp-form-field" name="city" value={input.city} onChange={e => handleChange(index, e)} />
                                                                    <span className="sp-error" />
                                                                </div>
                                                                <div className="sp-form-group">
                                                                    <label >{regData && decryptData(regData?.postal_code)}</label>
                                                                    <input type="text" id={`${errorBorder && input.postal_code.trim() === '' ? 'sp-error-border' : ''}`} className="sp-form-field" name="postal_code" value={input.postal_code} onChange={e => handleChange(index, e)} />
                                                                    <span className="sp-error" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="another-address-details">
                                            <h4>{regData && decryptData(regData?.main_contact_caption)}</h4>
                                            <div className="sp-form-group-merge">
                                                <div className="sp-form-group">
                                                    <label >{regData && decryptData(regData?.main_contact_name)}*</label>
                                                    <input type="text" className="sp-form-field" name='name' onChange={e => handleCustomerChange(0, 'name', e.target.value, true)} />
                                                    {mainNameError[0] && <p className='error'>{mainNameError[0]}</p>}
                                                </div>
                                                <div className="sp-form-group">
                                                    <label >{regData && decryptData(regData?.main_contact_email)}*</label>
                                                    <input type="text" className="sp-form-field" name='email' onChange={e => handleCustomerChange(0, 'email', e.target.value, true)} />
                                                    {mainEmailError[0] && <p className='error'>{mainEmailError[0]}</p>}
                                                    <span className="sp-error">
                                                        <span className="sp-icon">
                                                            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16M9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0" fill="#D72C0D" /></svg>
                                                        </span>
                                                        <span className="sp-text">Email already exist</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="sp-add-address">
                                                <h4>{regData && decryptData(regData?.add_customer_caption)}</h4>
                                                <button className="btn sp-btn-outline" type='button' onClick={handleCustomerDetailField}>{regData && decryptData(regData?.add_customer_btn)}</button>
                                            </div>
                                            {
                                                customerDetailField.length ?
                                                    customerDetailField.map((field, index) => (
                                                        <>
                                                            <div className="sp-form-group-merge" key={index}>
                                                                <div className="sp-form-group">
                                                                    <label >{regData && decryptData(regData?.add_customer_name)}*</label>
                                                                    <input type="text" className="sp-form-field" name='name' value={field.name} onChange={e => handleCustomerChange(index, 'name', e.target.value)} />
                                                                    {errors[index] && <p className='error'>{errors[index]}</p>}
                                                                </div>
                                                                <div className="sp-form-group">
                                                                    <label >{regData && decryptData(regData?.add_customer_email)}*</label>
                                                                    <input type="text" className="sp-form-field" name='email' value={field.email} onChange={e => handleCustomerChange(index, 'email', e.target.value)} />
                                                                    {emailErrors[index] && <p className='error'>{emailErrors[index]}</p>}
                                                                    <span className="sp-error">
                                                                        <span className="sp-icon">
                                                                            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16M9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0" fill="#D72C0D" /></svg>
                                                                        </span>
                                                                        <span className="sp-text">Email already exist</span>
                                                                    </span>
                                                                    <span className="sp-error">
                                                                        <span className="sp-icon">
                                                                            <svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M10 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16M9 9a1 1 0 0 0 2 0V7a1 1 0 1 0-2 0zm0 4a1 1 0 1 0 2 0 1 1 0 0 0-2 0" fill="#D72C0D" /></svg>
                                                                        </span>
                                                                        <span className="sp-text">Customer email can't be same as main contact.</span>
                                                                    </span>
                                                                </div>
                                                                <div className="sp-form-group flex-0">
                                                                    <button className="btn sp-btn-outline sp-btn-icon" type='button' onClick={() => customerDetailFieldRemove(index)}><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.5 8.25a.75.75 0 0 1 .75.75v4.25a.75.75 0 1 1-1.5 0V9a.75.75 0 0 1 .75-.75M9.25 9a.75.75 0 0 0-1.5 0v4.25a.75.75 0 1 0 1.5 0z" fill="#5C5F62" /><path fill-rule="evenodd" clip-rule="evenodd" d="M7.25 5.25a2.75 2.75 0 1 1 5.5 0h3a.75.75 0 1 1 0 1.5H15v5.45c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311C12.72 17 11.88 17 10.2 17h-.4c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311C5 14.72 5 13.88 5 12.2V6.75h-.75a.75.75 0 0 1 0-1.5zm1.5 0a1.25 1.25 0 0 1 2.5 0zM6.5 6.75h7v5.45c0 .865-.001 1.423-.036 1.848-.033.408-.09.559-.128.633a1.5 1.5 0 0 1-.655.655c-.074.038-.225.095-.633.128-.425.035-.983.036-1.848.036h-.4c-.865 0-1.423-.001-1.848-.036-.408-.033-.559-.09-.633-.128a1.5 1.5 0 0 1-.656-.655c-.037-.074-.094-.225-.127-.633-.035-.425-.036-.983-.036-1.848z" fill="#5C5F62" /></svg></button>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))
                                                    : ''
                                            }
                                        </div>
                                        <div className="sp-btn-center">
                                            <button type='submit' className={`sp-btn-submit ${isSubmitting ? 'loading' : ''}`} disabled={isSubmitting}><span className='sp-btn-text'>{regData && decryptData(regData?.submit_btn)}</span></button>
                                        </div>
                                    </form>
                                </div>
                                : <div className="sp-w-100 sp-vh-100 sp-overflow-hidden">
                                    <div className="sp-d-flex sp-align-items-center sp-justify-content-center sp-h-100">
                                        <div className="sp-card">
                                            <h4 dangerouslySetInnerHTML={{ __html: decryptData(regData?.thank_you_message) }}></h4>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div> : ''
            }
        </>
    );
}

export default RegistrationForm;