/* eslint-disable no-console */
// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
import { extraFeeProductApi, extraFeeProductDeleteApi, frontErrorMsg } from './axios';


function ShopifyformatMoney(cents, money_format = null) {
    money_format = money_format?.replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
    const placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
    if (cents != '') {
        cents = number_format(cents, 2, '.', '');
        cents = cents.replace('.', '');
    }
    const matches = money_format?.match(placeholderRegex);
    let amount = '';
    if (matches) {
        switch (matches[1]) {
            case 'amount':
                amount = formatWithDelimiters(cents, 2, '.', '.');
                break;
            case 'amount_no_decimals':
                amount = formatWithDelimiters(cents, 0);
                break;
            case 'amount_with_comma_separator':
                amount = formatWithDelimiters(cents, 2, '.', ',');
                break;
            case 'amount_no_decimals_with_comma_separator':
                amount = formatWithDelimiters(cents, 0, '.', ',');
                break;
        }
    }
    return money_formate_replace(money_format, amount);
}

function money_formate_replace(money_format, amount = '') {
    let beginning = '{{',
        end = '}}',
        beginningPos = money_format?.indexOf(beginning),
        endPos = money_format?.indexOf(end);
    if (beginningPos === false || endPos === false) {
        return money_format;
    }
    const textToDelete = money_format?.substr(beginningPos, (endPos + end.length) - beginningPos);
    money_format = money_format?.replace(textToDelete, amount);
    money_format = money_format?.replace('amp', '');

    return html_entity_decode(money_format);
}

function html_entity_decode(string, quoteStyle = 1) {
    let html_entity_decode_json = {
        '&amp;': '&',
        '&lt;': '<',
        '&gt;': '>',
        '&nbsp;': ' ',
        '&iexcl;': '¡',
        '&cent;': '¢',
        '&pound;': '£',
        '&curren;': '¤',
        '&yen;': '¥',
        '&brvbar;': '¦',
        '&sect;': '§',
        '&uml;': '¨',
        '&copy;': '©',
        '&ordf;': 'ª',
        '&laquo;': '«',
        '&not;': '¬',
        '&shy;': '',
        '&reg;': '®',
        '&macr;': '¯',
        '&deg;': '°',
        '&plusmn;': '±',
        '&sup2;': '²',
        '&sup3;': '³',
        '&acute;': '´',
        '&micro;': 'µ',
        '&para;': '¶',
        '&middot;': '·',
        '&cedil;': '¸',
        '&sup1;': '¹',
        '&ordm;': 'º',
        '&raquo;': '»',
        '&frac14;': '¼',
        '&frac12;': '½',
        '&frac34;': '¾',
        '&iquest;': '¿',
        '&Agrave;': 'À',
        '&Aacute;': 'Á',
        '&Acirc;': 'Â',
        '&Atilde;': 'Ã',
        '&Auml;': 'Ä',
        '&Aring;': 'Å',
        '&AElig;': 'Æ',
        '&Ccedil;': 'Ç',
        '&Egrave;': 'È',
        '&Eacute;': 'É',
        '&Ecirc;': 'Ê',
        '&Euml;': 'Ë',
        '&Igrave;': 'Ì',
        '&Iacute;': 'Í',
        '&Icirc;': 'Î',
        '&Iuml;': 'Ï',
        '&ETH;': 'Ð',
        '&Ntilde;': 'Ñ',
        '&Ograve;': 'Ò',
        '&Oacute;': 'Ó',
        '&Ocirc;': 'Ô',
        '&Otilde;': 'Õ',
        '&Ouml;': 'Ö',
        '&times;': '×',
        '&Oslash;': 'Ø',
        '&Ugrave;': 'Ù',
        '&Uacute;': 'Ú',
        '&Ucirc;': 'Û',
        '&Uuml;': 'Ü',
        '&Yacute;': 'Ý',
        '&THORN;': 'Þ',
        '&szlig;': 'ß',
        '&agrave;': 'à',
        '&aacute;': 'á',
        '&acirc;': 'â',
        '&atilde;': 'ã',
        '&auml;': 'ä',
        '&aring;': 'å',
        '&aelig;': 'æ',
        '&ccedil;': 'ç',
        '&egrave;': 'è',
        '&eacute;': 'é',
        '&ecirc;': 'ê',
        '&euml;': 'ë',
        '&igrave;': 'ì',
        '&iacute;': 'í',
        '&icirc;': 'î',
        '&iuml;': 'ï',
        '&eth;': 'ð',
        '&ntilde;': 'ñ',
        '&ograve;': 'ò',
        '&oacute;': 'ó',
        '&ocirc;': 'ô',
        '&otilde;': 'õ',
        '&ouml;': 'ö',
        '&divide;': '÷',
        '&oslash;': 'ø',
        '&ugrave;': 'ù',
        '&uacute;': 'ú',
        '&ucirc;': 'û',
        '&uuml;': 'ü',
        '&yacute;': 'ý',
        '&thorn;': 'þ',
        '&yuml;': 'ÿ'
    };
    if (string) {
        const OPTS = {
            'ENT_COMPAT': 1,
            'ENT_QUOTES': 2,
            'ENT_NOQUOTES': 3
        };
        let entities = html_entity_decode_json;
        if (isNaN(quoteStyle)) {
            quoteStyle = OPTS[quoteStyle];
        }
        if (quoteStyle != 3) {
            entities['&quot;'] = '"';
        }
        if (quoteStyle == 2) {
            entities['&#39;'] = "'";
        }
        return string.replace(/&([^;]+);/gm, function (match) {
            return entities[match] || match;
        });
    } else {
        return string;
    }
}

function number_format(number, decimals, dec_point, thousands_sep) {
    /* Strip all characters but numerical ones.*/
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    let n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point;

    /* Fix for IE parseFloat(0.55).toFixed(0) = 0;*/
    const toFixedFix = function (n, prec) {
        let k = Math.pow(10, prec);
        return '' + Math.round(n * k) / k;
    };
    let s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

function defaultOption(opt, def) {
    return (typeof opt == 'undefined' || opt == null) ? def : opt;
}

function formatWithDelimiters(number, precision, thousands = '', decimal = '') {
    precision = defaultOption(precision, 2);
    thousands = (thousands != '') ? defaultOption(thousands, ',') : '';
    decimal = (decimal != '') ? defaultOption(decimal, '.') : '';
    if (typeof number == 'undefined' || number == '') {
        return 0;
    }

    number = number_format((number / 100), precision);
    let parts = number.split('.');
    let dollars = parts[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/, '1' + thousands);
    let cents = '';
    if (decimal != '') {
        cents = typeof parts[1] !== 'undefined' ? decimal + '' + parts[1] : '';
    } else {
        cents = typeof parts[1] !== 'undefined' ? parts[1] : '';
    }
    return dollars + '' + cents;
}

const productDiscount = (qty, discount, isCurrency, discount_type, priceLine, flag, offer_type) => {
    try {
        let discountPrice;
        let discountCal = discountCalculation(discount, discount_type, priceLine, flag, qty, offer_type);
        let upperKey = null;
        for (let i = 0; i < discountCal.length; i++) {
            const obj = discountCal[i];
            let toValue = obj.to != '' ? obj.to : qty;
            let qtyValue = obj.quantity;
            let fromVal = obj.from != '' ? obj.from : qty;
            if ((parseInt(fromVal) <= qty && (upperKey === null || parseInt(fromVal) >= upperKey) && parseInt(toValue) >= qty) || (parseInt(qtyValue) <= qty && (upperKey === null || parseInt(qtyValue) >= upperKey))) {
                upperKey = parseInt(fromVal) ? parseInt(fromVal) : parseInt(qtyValue);
                discountPrice = obj['discount'];
            }
        }
        if (isCurrency == 'true') {
            discountPrice = discountPrice && (discountPrice);
        } else {
            discountPrice = discountPrice && (discountPrice);
        }
        return discountPrice >= 0 && discountPrice;
    } catch (error) {
        console.log('error', error);
    }
};

const discountCalculation = (dis, discount_type, priceLine, flag, qty, offer_type) => {
    let discountData = [];
    for (let i = 0; i < dis.length; i++) {
        const obj = dis[i];
        let discount = obj;
        if (obj.hasOwnProperty('from') && !obj.from) {
            return discountData;
        }
        if (discount_type == 0) {
            if (flag) {
                if (offer_type == 1) {
                    if (obj['quantity'] == qty) {
                        discount['discount'] = Boolean(Number(obj['discount'])) && obj['discount'];
                    } else {
                        if (obj['quantity'] <= qty) {
                            let disQty = qty - obj['quantity'];
                            let originalTotal = priceLine * obj['quantity'];
                            let disAmt = originalTotal * discount['discount'] / 100;
                            let percentageVal = priceLine * qty - (originalTotal - disAmt + (priceLine * disQty));
                            discount['discount'] = percentageVal / (priceLine * qty) * 100;
                        }
                    }
                } else {
                    discount['discount'] = Boolean(Number(obj['discount'])) && obj['discount'];
                }
            } else {
                discount['discount'] = priceLine - (priceLine * obj['discount'] / 100);
                discount['discount'] = Math.sign(discount['discount']) != -1 ? discount['discount'] : priceLine;
            }
        } else if (discount_type == 1) {
            if (flag) {
                if (offer_type == 1) {
                    if (obj['quantity'] == qty) {
                        discount['discount'] = (obj['discount'] * Shopify.currency.rate) < priceLine && obj['discount'] * qty;
                    } else {
                        if (obj['quantity'] <= qty) {
                            let disQty = qty - obj['quantity'];
                            discount['discount'] = (Number(discount['discount']) * Number(discount['quantity'])) + (priceLine * disQty);
                        }
                    }
                } else {
                    discount['discount'] = (obj['discount'] * Shopify.currency.rate) < priceLine && obj['discount'] * qty;
                }
            } else {
                discount['discount'] = (obj['discount'] * Shopify.currency.rate) <= priceLine ? discount['discount'] : priceLine;
            }
        } else if (discount_type == 2) {
            if (flag) {
                if (offer_type == 1) {
                    discount['discount'] = Boolean(Number(obj['discount'])) && obj['discount'];
                } else {
                    discount['discount'] = Boolean(Number(obj['discount'])) && obj['discount'];
                }
            } else {
                discount['discount'] = obj['discount'];
                discount['discount'] = obj['discount'] <= priceLine ? discount['discount'] : 0;
            }
        }
        discountData.push(discount);
    }
    return discountData;
};

const getAllDomElements = (defaultNodeElements, settingsNodeElements, rootNode) => {
    try {
        const cartData = settingsNodeElements.concat(defaultNodeElements);
        const dataArr = [...new Set(cartData)];
        if (dataArr.length) {
            for (let i = 0; i < dataArr.length; i++) {
                const item = dataArr[i].trim();
                const currentItemClass = rootNode ? rootNode.querySelectorAll(item) : document.querySelectorAll(item);
                if (currentItemClass.length) {
                    return currentItemClass;
                }
            }
        } else {
            return null;
        }
    } catch (error) {
        console.log('error---------', error);
    }
};

const setDiscountPrice = (listclass, price, priceLine) => {
    let currencySymbol = ShopifyformatMoney(priceLine, currencyWihoutFormat);
    let listClassChildren = listclass.children;
    listclass.innerHTML = '';
    listclass.insertAdjacentHTML('beforeend', `<span class = "sp-discount-price">${currencySymbol}</span>`);
    listclass.insertAdjacentHTML('beforeend', `<span class = "sp-discount-price">${Shopify.shop === 'pumbaworkz.myshopify.com' ? price + Shopify.currency.active : price}</span>`);
    if (price) {
        if (listClassChildren.length > 0) {
            listClassChildren[0].style.textDecoration = 'line-through';
        } else {
            listclass.style.textDecoration = 'line-through';
        }
    }
};

const extraFeeRuleCheck = (fees_and_free_shipping, extra_free_product, discountAmt, qty, extra_fee_amt, freeShipping) => {
    for (let i = 0; i < fees_and_free_shipping?.item.length; i++) {
        let { to, from, fee, fee_type } = fees_and_free_shipping?.item[i];
        if ((from === '' || qty >= from) && (to === '' || qty <= to)) {
            freeShipping[from] = true;
            if (fee_type === '0') {
                let percentage = (discountAmt * fee) / 100;
                extra_fee_amt += percentage;
            } else {
                extra_fee_amt += Number(fee);
            }
        }
    }
    extra_free_product['value'] = extra_fee_amt;
    return extra_fee_amt;
}

const extraFessNormalOrderFun = async (fees, cart, product, cartCount, truty = false) => {
    let extraProducts = [];
    let flag = true;
    // let deletedIndices = [];
    let extraFeesProductDeleteData = [];
    // Create a map for quick lookup of main products by title
    // let mainProductMap = new Map(cart.map((item, index) => [item.title, { ...item, index }]));
    // Loop through each product in the cart
    // for (let product of cart) {
    let extraFess = Object.values(fees)[0];
    if (truty) {
        cart.reduce((acc, curr) => {
            if ((Object.keys(extraFess).includes(curr.variant_id.toString()))) {
                cartCount = acc - 1;
            }
            return cartCount;
        }, cartCount);
    }
    let qty = truty ? cartCount : product.quantity;
    let productTitle = truty ? 'extra Fees Product' : product.title;
    // Loop through extra fee slabs
    if (!(Object.keys(extraFess).includes(product.variant_id.toString()))) {
        for (let feeKey in extraFess) {
            // for (let fee of feeList) {
            // let feeKey = Object.keys(fee)[0];
            let { to, from } = extraFess[feeKey];

            // Check if the quantity falls within the slab range
            if ((from === '' || qty >= from) && (to === '' || qty <= to)) {
                // console.log('456');
                // Add the extra fee product if conditions are met
                // if (!extraProducts.some(extra => extra.id === feeKey && extra.properties === productTitle)) {
                extraProducts.push({ id: feeKey, propertity_name: productTitle });
                // extraProductMapping[feeKey] = productTitle;
                // }
            } else {
                // Check if extra product should be removed
                // console.log('123',feeKey);
                extraFeesProductDeleteData.push({
                    id: Number(feeKey),
                    propertity_name: truty ? 'extra Fees Product' : product.title
                });
                // if (extraProductMapping[feeKey] === productTitle) {
                // console.log('indexToRemove',mainProductMap);
                // let indexToRemove = mainProductMap.get(feeKey)?.index;
                // if (indexToRemove !== undefined) {
                //     deletedIndices.push({ line: indexToRemove });
                // extraProducts = extraProducts.filter(extra => !(extra.id === feeKey && extra.properties === productTitle));
                // delete extraProductMapping[feeKey];
                // }
                // }
            }
            // }
        }
    }
    // }
    let productDeletefilterOutData = extraFeesProductDeleteData.map(zItem => {
        return cart.findIndex(xItem => xItem.variant_id === zItem.id && xItem.properties['Extra fee for'] === zItem.propertity_name);
    }).filter(index => index !== -1);
    let productAddfilterOutData = extraProducts.filter(zItem => {
        return !cart.some(xItem => xItem.variant_id == zItem.id && xItem.properties['Extra fee for'] == zItem.propertity_name);
    });

    if (isRealValue(productDeletefilterOutData)) {
        flag = false;
        await extraFeeProductDeleteApi(productDeletefilterOutData,productAddfilterOutData);
    }

    if (isRealValue(productAddfilterOutData) && flag) {
        await extraFeeProductApi(productAddfilterOutData);
    }
}

const extraFessDraftOrderFun = (cart, fees_and_free_shipping, discountValue, truty = false, cartSubTotal, cartCount) => {
    let extra_free_product = {};
    let extra_fee_amt = null;
    let freeShipping = {};
    if (truty) {
        extra_fee_amt = extraFeeRuleCheck(fees_and_free_shipping, extra_free_product, cartSubTotal, cartCount, extra_fee_amt, freeShipping);
    } else {
        cart?.map((item, index) => {
            let priceLine = item.selling_plan_allocation ? item.selling_plan_allocation?.compare_at_price / 100 * item.quantity : item.original_line_price / 100;
            let discountAmt = isRealValue(discountValue) ? discountValue[index] ? priceLine - discountValue[index] : priceLine : priceLine;
            discountAmt = Number((discountAmt / Shopify.currency.rate).toFixed(2));
            let qty = item.quantity;
            // for (let i = 0; i < fees_and_free_shipping?.item.length; i++) {
            //     let { to, from, fee, fee_type } = fees_and_free_shipping?.item[i];
            //     if ((from === '' || qty >= from) && (to === '' || qty <= to)) {
            //         if (fee_type === '0') {
            //             let percentage = (discountAmt * fee) / 100;
            //             extra_fee_amt += percentage;
            //         } else {
            //             extra_fee_amt += Number(fee);
            //         }
            //         extra_free_product['value'] = extra_fee_amt;
            //     }
            // }
            extra_fee_amt = extraFeeRuleCheck(fees_and_free_shipping, extra_free_product, discountAmt, qty, extra_fee_amt, freeShipping);
        });
    }
    if (typeof extra_fee_amt === 'number') {
        if (fees_and_free_shipping?.quantity == 0) {
            fees_and_free_shipping.item.forEach((item) => {
                if (freeShipping[item.from]) {
                    if (item.free_shipping == 1) {
                        extra_free_product['shipping_status'] = true;
                    }
                }

            });
        } else if (fees_and_free_shipping?.quantity == 1) {
            if (fees_and_free_shipping?.free_shipping_calculation == 0) {
                fees_and_free_shipping.item.forEach((item) => {
                    if (freeShipping[item.from]) {
                        if (item.free_shipping == 1) {
                            extra_free_product['shipping_status'] = true;
                        } else {
                            extra_free_product['shipping_status'] = false;
                        }
                    }
                })
            } else if (fees_and_free_shipping?.free_shipping_calculation == 1) {
                fees_and_free_shipping.item.forEach((item) => {
                    if (freeShipping[item.from]) {
                        if (item.free_shipping == 1) {
                            extra_free_product['shipping_status'] = true;
                        }
                    }
                })
            }
        }
        if (!extra_free_product['shipping_status']) {
            extra_free_product['shipping_status'] = false;
        }
        extra_free_product['name'] = fees_and_free_shipping?.fee_name;
        return extra_free_product;
    } else {
        return {};
    }
}

const discountedProduct = (productData, variantid, TotalPriceClass, cartItemList, perDiscountSel, subTotal, orderType, both_discount_applied, security_token, apply_discount_type, extraFeesProduct, fees_and_free_shipping, cartCounts, qtySelector, extra_fee_status) => {
    try {
        let prepared_data = {};
        let TotalDiscouts = 0;
        let minMaxDiscountValue = true;
        let original_subTotal = subTotal;
        let productArr = [...productData];
        let qtyArr = isRealValue(qtySelector) ? [...qtySelector] : null;
        let cartCount = productData?.length;
        cartCount -= 1;
        let CartItemClass = cartItemList ? [...cartItemList] : '';
        let isDiscount = 0;
        let flags = {};
        let cartcounter = [];
        let title = [];
        let discountValue = [];
        let cartSubTotal = 0;
        let extraFee_data = {};
        productArr?.map((item, i) => {
            let shopifyDiscountCheck = apply_discount_type != 0 ? !isRealValue(item?.line_level_discount_allocations) : true;
            let priceLine = (orderType == 0 || orderType == 1) && item.selling_plan_allocation && shopifyDiscountCheck ? item.selling_plan_allocation?.compare_at_price / 100 * item.quantity : apply_discount_type === '0' ? item.original_line_price / 100 : item.final_line_price / 100;
            let combinationPrice = orderType == 2 ? apply_discount_type != 0 ? item.final_line_price / 100 : item.original_line_price / 100 : false;
            let combinationWithSubscription = orderType == 2 && item.selling_plan_allocation ? Number(both_discount_applied) : 1;
            let discount;
            let productVariant = [];
            let slabId = [];
            let discountType = null;
            let extraFessDiscountType = null;
            let extraFeesProductCheck = isRealValue(extraFeesProduct) ? !extraFeesProduct[item.product_id] : true;
            if (extra_fee_status == 1 && orderType == 2 && isRealValue(extraFeesProduct) && extraFeesProduct[item.product_id] && qtyArr && qtyArr[i]) {
                qtyArr[i].classList.add('sp-hidden');
            }
            if (extraFeesProductCheck) {
                for (let index = 0; index < variantid.length; index++) {
                    let discountVariantId = variantid[index];
                    let productVariantId = discountVariantId.apply_to_value ? JSON.parse(discountVariantId.apply_to_value) : '';
                    let product_variants_ids = [];
                    if (discountVariantId?.apply_to_type == '2' && discountVariantId?.tag_product_id && isRealValue(discountVariantId?.tag_product_id)) {
                        product_variants_ids = productArr?.map((item) =>  discountVariantId?.tag_product_id.includes(item?.product_id.toString()) && item.variant_id.toString()).filter(Boolean);
                    }
                    let discountProductVariantId = isRealValue(productVariantId) ? [].concat(...Object.values(productVariantId)) : product_variants_ids;
                    let b2bVariantId = [];
                    let offerValue = JSON.parse(discountVariantId.offer_value);
                    let cartValueMinMax = JSON.parse(discountVariantId.cart_value);
                    if (!(productVariant.includes(item.id)) && combinationWithSubscription) {
                        if (discountVariantId?.catalogData?.length) {
                            for (let index = 0; index < discountVariantId.catalogData.length; index++) {
                                const element = discountVariantId.catalogData[index];
                                let productData = JSON.parse(element.products);
                                for (const productkey in productData) {
                                    let variantData = productData[productkey];
                                    for (const varaiantId in variantData) {
                                        let discountData = variantData[varaiantId];
                                        if (isRealValue(discountData)) {
                                            item.selling_plan_allocation && orderType != 2 ? productArr?.filter((item) => item.id == varaiantId && b2bVariantId.push(varaiantId)) : '';
                                        }
                                        if (varaiantId == item.id && !(slabId.includes(item.id))) {
                                            slabId.push(item.id);
                                            let discountData = variantData[varaiantId];
                                            discountProductVariantId.push(varaiantId);
                                            discount = isRealValue(discountData) && productDiscount(item.quantity, discountData?.slab, 'true', discountData?.volume_pricing, item.price / 100, true, discountVariantId?.offer_type);
                                            discountType = discountData.volume_pricing;
                                        }
                                    }
                                }
                            }
                        }
                        let exclusive_Inclusive = discountVariantId.product_allow_type == 1 ? !(discountProductVariantId.includes(item.id.toString())) : discountProductVariantId.includes(item.id.toString());
                        if (exclusive_Inclusive && priceLine > 0) {
                            let temp = [];
                            productVariant.push(item.id);
                            if (discountVariantId?.offer_type == 0 || discountVariantId?.offer_type == 1) {
                                let price = item.selling_plan_allocation ? item.selling_plan_allocation?.compare_at_price / 100 : item.price / 100;
                                discount = productDiscount(item.quantity, offerValue, 'true', discountVariantId.discount_type, price, true, discountVariantId?.offer_type);
                            }
                            flags[item.id] = Number(discount);
                            if (discount || discount === 0) {
                                flags[item.id] = Number(discount);
                                // cartcounter.push(cartCount);
                                cartcounter.push(i);
                                let finalPrice = original_subTotal;
                                let minCartValue = cartValueMinMax.Min != '' ? cartValueMinMax.Min : finalPrice;
                                let maxCartValue = cartValueMinMax.Max != '' ? cartValueMinMax.Max : finalPrice;
                                minMaxDiscountValue = finalPrice >= minCartValue && finalPrice <= maxCartValue;
                                if (discountVariantId.discount_type == 0 || discountType == 0) {
                                    discount = discount <= 100 ? (((combinationPrice ? combinationPrice : priceLine) * discount / 100)) : false;
                                    if (combinationPrice) {
                                        TotalDiscouts += priceLine > discount ? discount : priceLine;
                                    } else {
                                        TotalDiscouts += minMaxDiscountValue && discount;
                                    }
                                }
                                if (discountVariantId.discount_type == 1 || discountType == 1) {
                                    extraFessDiscountType = 1;
                                    discount = discount * Shopify.currency.rate;
                                    if (combinationPrice) {
                                        discount = discount < combinationPrice ? combinationPrice - discount : false;
                                        discount = discount && priceLine > discount ? priceLine - discount : 0;
                                    } else {
                                        discount = discount < priceLine ? discount : false;
                                    }
                                    TotalDiscouts += minMaxDiscountValue && priceLine - discount;
                                }
                                if (discountVariantId.discount_type == 2 || discountType == 2) {
                                    discount = discount * Shopify.currency.rate;
                                    if (combinationPrice) {
                                        discount = discount < combinationPrice ? discount : false;
                                    } else {
                                        discount = discount <= priceLine ? discount : false;
                                    }
                                    TotalDiscouts += minMaxDiscountValue && discount;
                                }
                                discount = minMaxDiscountValue ? discount : false;
                                if (minMaxDiscountValue && (discount || discount === 0)) {
                                    isDiscount += 1;
                                    let discountPrice = discountVariantId.discount_type == 1 || discountType == 1 ? discount : combinationPrice && priceLine > discount ? priceLine - discount : !combinationPrice ? priceLine - discount : 0;
                                    if (perDiscountSel.length) {
                                        let cartItemPriceList = getAllDomElements(perDiscountSel, [], CartItemClass[i]);
                                        let currencySymbol = ShopifyformatMoney(discountPrice, currencyWihoutFormat);
                                        cartItemPriceList && cartItemPriceList.forEach((nodeItem) => {
                                            setDiscountPrice(nodeItem, currencySymbol, priceLine);
                                        });
                                    }
                                    let currencySymbol = ShopifyformatMoney(discountPrice, currencyWihoutFormat);
                                    const cartItemPriceList = getAllDomElements(TotalPriceClass, [], CartItemClass[i]);
                                    const store = ['pumbaworkz.myshopify.com', 'cc8a02-3.myshopify.com'];
                                    if (store.includes(Shopify.shop) && cartItemPriceList) {
                                        setDiscountPrice(cartItemPriceList[0], currencySymbol, priceLine);
                                    } else if (cartItemPriceList) {
                                        cartItemPriceList.forEach((nodeItem) => {
                                            setDiscountPrice(nodeItem, currencySymbol, priceLine);
                                        });
                                    } else if (meta.page.pageType == 'cart') {
                                        frontErrorMsg(security_token, Shopify.shop, 'price not show in cart page');
                                    }
                                    let prepared_obj = {
                                        variant_id: item.id,
                                        discount_value: priceLine - discount,
                                        product_quantity: item.quantity
                                    };
                                    temp.push(prepared_obj);
                                    prepared_data[cartCount] = temp;
                                    original_subTotal = discountVariantId.discount_type == 1 || discountType == 1 ? finalPrice - (priceLine - discount) : finalPrice - discount;
                                }
                            }
                        }
                    }
                    if (discountVariantId.apply_to_value === null && combinationWithSubscription && discountVariantId.apply_to_type == 0 && !(productVariant.includes(item.id))) {
                        let temp = [];
                        productVariant.push(item.id);
                        let price = item.selling_plan_allocation ? item.selling_plan_allocation?.compare_at_price / 100 : item.price / 100;
                        discount = productDiscount(item.quantity, offerValue, 'true', discountVariantId.discount_type, price, true, discountVariantId?.offer_type);
                        if ((discount || discount === 0) && priceLine > 0) {
                            // cartcounter.push(cartCount);
                            cartcounter.push(i);
                            flags[item.id] = discount;
                            let finalPrice = original_subTotal;
                            let minCartValue = cartValueMinMax.Min != '' ? cartValueMinMax.Min : finalPrice;
                            let maxCartValue = cartValueMinMax.Max != '' ? cartValueMinMax.Max : finalPrice;
                            minMaxDiscountValue = finalPrice >= minCartValue && finalPrice <= maxCartValue;
                            if (discountVariantId.discount_type == 0) {
                                discount = discount <= 100 ? (((combinationPrice ? combinationPrice : priceLine) * discount / 100)) : false;
                                if (combinationPrice) {
                                    TotalDiscouts += priceLine > discount ? discount : priceLine;
                                } else {
                                    TotalDiscouts += minMaxDiscountValue && discount;
                                }
                            }
                            if (discountVariantId.discount_type == 1) {
                                extraFessDiscountType = 1;
                                discount = discount * Shopify.currency.rate;
                                if (combinationPrice) {
                                    discount = discount < combinationPrice ? combinationPrice - discount : false;
                                    discount = discount && priceLine > discount ? priceLine - discount : false;
                                } else {
                                    discount = discount < priceLine ? discount : false;
                                }
                                TotalDiscouts += minMaxDiscountValue && priceLine - discount;
                            }
                            if (discountVariantId.discount_type == 2) {
                                discount = discount * Shopify.currency.rate;
                                if (combinationPrice) {
                                    discount = discount < combinationPrice ? discount : false;
                                } else {
                                    discount = discount <= priceLine ? discount : false;
                                }
                                TotalDiscouts += minMaxDiscountValue && discount;
                            }
                            discount = minMaxDiscountValue ? discount : false;
                            if (minMaxDiscountValue && (discount || discount === 0)) {
                                isDiscount += 1;
                                let discountPrice = discountVariantId.discount_type == 1 ? discount : combinationPrice && priceLine > discount ? priceLine - discount : !combinationPrice ? priceLine - discount : 0;
                                if (perDiscountSel.length) {
                                    let cartItemPriceList = getAllDomElements(perDiscountSel, [], CartItemClass[i]);
                                    let currencySymbol = ShopifyformatMoney(discountPrice, currencyWihoutFormat);
                                    cartItemPriceList && cartItemPriceList.forEach((nodeItem) => {
                                        setDiscountPrice(nodeItem, currencySymbol, priceLine);
                                    });
                                }
                                let currencySymbol = ShopifyformatMoney(discountPrice, currencyWihoutFormat);
                                const cartItemPriceList = getAllDomElements(TotalPriceClass, [], CartItemClass[i]);
                                if (Shopify.shop === 'pumbaworkz.myshopify.com' && cartItemPriceList) {
                                    setDiscountPrice(cartItemPriceList[0], currencySymbol, priceLine);
                                } else if (cartItemPriceList) {
                                     cartItemPriceList.forEach((nodeItem) => {
                                        setDiscountPrice(nodeItem, currencySymbol, priceLine);
                                    });
                                } else if (meta.page.pageType == 'cart') {
                                    frontErrorMsg(security_token, Shopify.shop, 'price not show in cart page');
                                }
                                let prepared_obj = {
                                    variant_id: item.id,
                                    discount_value: priceLine - discount,
                                    product_quantity: item.quantity
                                };
                                temp.push(prepared_obj);
                                prepared_data[cartCount] = temp;
                                original_subTotal = discountVariantId.discount_type == 1 ? finalPrice - (priceLine - discount) : finalPrice - discount;
                            }
                        }
                    }
                }
            }
            discountValue.push(extraFessDiscountType === 1 ? priceLine - discount : discount);
            cartSubTotal += discount ? extraFessDiscountType === 1 ? discount : priceLine - discount : priceLine;
            if (extra_fee_status == 1 && orderType == 2 && isRealValue(extraFeesProduct)) {
                if (fees_and_free_shipping?.quantity === '1') {
                    extraFessNormalOrderFun(extraFeesProduct, productData, item);
                } else if (fees_and_free_shipping?.quantity === '0') {
                    extraFessNormalOrderFun(extraFeesProduct, productData, item, cartCounts, true);
                }
            }
            if (!item?.properties['Extra fee for']) {
                title.push(item.title);
            }
            cartCount = cartCount - 1;
        });
        if (extra_fee_status == 1 && orderType == 0) {
            if (fees_and_free_shipping?.quantity === '1') {
                extraFee_data = extraFessDraftOrderFun(productData, fees_and_free_shipping, discountValue);
            } else if (fees_and_free_shipping?.quantity === '0') {
                extraFee_data = extraFessDraftOrderFun(productData, fees_and_free_shipping, discountValue, true, cartSubTotal, cartCounts);
            }
        }
        if (extra_fee_status == 1 && orderType == 2) {
            let deleteProduct = fees_and_free_shipping?.quantity === '1' && productData.findIndex(item => item?.properties['Extra fee for'] && !title.includes(item?.properties['Extra fee for']));
            if (((deleteProduct || deleteProduct === 0) && deleteProduct != -1)) {
                extraFeeProductDeleteApi([deleteProduct]);
            } else if (!isRealValue(title) && fees_and_free_shipping?.quantity === '0') {
                extraFeeProductDeleteApi([0]);
            } else if (fees_and_free_shipping?.quantity === '0') {
                let deleteProduct = productData.findIndex(item => item?.properties['Extra fee for'] && item?.properties['Extra fee for'] != 'extra Fees Product');
                if (deleteProduct != -1) {
                    extraFeeProductDeleteApi([deleteProduct]);
                }
            }
        }
        let checked = isRealValue(flags) ? Object.values(flags).some((element) => typeof element === 'number') : '';
        // let cartData = [...productData];
        // cartData = cartData.reverse();
        // let cartItemData = cartItemList && [...cartItemList].reverse();
        // /* let values = isRealValue(flags) && Object.keys(flags);
        // console.log(flags,cartData);
        // let cartItemLists = cartItemList ? [...cartItemList].reverse() : '';
        // if (checked) {
        // console.log(flags,cartData,cartItemData); */
        if (checked) {
            productData?.map((item, i) => {
                // console.log(values.includes(item.id.toString()));
                if (item.selling_plan_allocation || apply_discount_type === '0') {
                    if (!cartcounter.includes(i)) {
                        let priceLine = (orderType == 0 || orderType == 1) && item.selling_plan_allocation ? item.selling_plan_allocation?.compare_at_price / 100 * item.quantity : item.original_line_price / 100;
                        // if (typeof flags[i] != 'number') {
                        if (perDiscountSel.length) {
                            let cartItemPriceList = getAllDomElements(perDiscountSel, [], cartItemList[i]);
                            cartItemPriceList && cartItemPriceList.forEach((nodeItem) => {
                                setDiscountPrice(nodeItem, '', priceLine / item.quantity);
                            });
                        }
                        const cartItemPriceList = getAllDomElements(TotalPriceClass, [], cartItemList ? cartItemList[i] : '');
                        cartItemPriceList && cartItemPriceList.forEach((nodeItem) => {
                            setDiscountPrice(nodeItem, '', priceLine);
                        });
                        // }
                    }
                }
            });
        }
        // }
        window.thirdPartyAppSupport = () => {
            return prepared_data;
        };
        let totalDiscount = {
            Discount: TotalDiscouts ? TotalDiscouts : false,
            extraFee: isRealValue(extraFee_data) ? extraFee_data : false
        }
        return totalDiscount;
    } catch (error) {
        console.log('error------------', error);
    }
};

const collectionPageDiscountShow = (Collectionpageclass, qtyandprice, variantid, currency) => {
    try {
        Object.keys(Collectionpageclass).map((vardata) => {
            const collectionvarid = Collectionpageclass[vardata].dataset.variantId;
            if (qtyandprice['1'] && collectionvarid == variantid[0]) {
                let collectionElm = Collectionpageclass[vardata];
                if (collectionElm && collectionElm.classList.contains('sp-price')) {
                    collectionElm.classList.remove('sp-price');
                }
                let price = ShopifyformatMoney(qtyandprice['1'] * currency, currencyWihoutFormat);
                const element = collectionElm != undefined && collectionElm.cloneNode();
                if (element) {
                    element.classList.add('sp-price');
                    element.innerHTML = price;
                }
                collectionElm != undefined ? collectionElm.style.textDecoration = 'line-through' : '';
                collectionElm != undefined ? collectionElm.after(element) : '';
            }
        });
        ComparePriceRemove();
    } catch (error) {
        console.log('error--------', error);
    }
};

const ComparePriceRemove = () => {
    const priceContainer = document.querySelectorAll('.sp-price');
    priceContainer && Object.keys(priceContainer).map((vardata) => {
        let comparePriceRemove = priceContainer[vardata].parentNode;
        let comparePriceNode = comparePriceRemove ? comparePriceRemove.querySelector('s') : '';
        comparePriceNode ? comparePriceNode.style.display = 'none' : '';
    });
};

const getAttribute = () => {
    try {
        let attribute = [];
        let attributeObj = {};
        let selectorName = document.querySelectorAll('[name]');
        if (selectorName) {
            for (let i = 0; i < selectorName.length; i++) {
                let getAttribute = selectorName[i].getAttribute('name');
                if (getAttribute.includes('attributes')) {
                    attribute.push(getAttribute);
                }
            }
            let attriLen = attribute.length;
            if (attriLen > 0) {
                while (attriLen--) {
                    let attributeStr = attribute[attriLen];
                    let attributeVal = document.querySelectorAll(`[name="${attributeStr}"]`)[0].value;
                    attributeStr = attributeStr.replace('attributes', '').replace(/[\[\]']+/g, '');
                    attributeObj[attributeStr] = attributeVal;
                }
            }
        }
        return attributeObj;
    } catch (error) {
        console.log('error---->>', error);
    }
};

function isRealValue(obj) {
    const objStr = JSON.stringify(obj);
    return !!(obj && objStr != '[]' && objStr != '{}' && objStr.length > 0);
}

const LocalStorageSetData = (local_storage_name, icartSetLocalStorageData, LocalDataType = {}) => {
    let final_local_storage_value_set = icartSetLocalStorageData ? JSON.stringify(icartSetLocalStorageData) : LocalDataType;
    localStorage.setItem(local_storage_name, final_local_storage_value_set);
};

const LocalStorageGetData = (local_storage_name, icartLocalDataType = {}) => {
    let get_discount_data = localStorage.getItem(local_storage_name);
    let parse_discount_data;
    try {
        parse_discount_data = get_discount_data && get_discount_data != {} ? JSON.parse(get_discount_data) : icartLocalDataType;
        return parse_discount_data;
    } catch (error) {
        console.log('error-----------', error);
    }
};

const collectionPrice = (props, currency, Collectionpageclass, productData) => {
    const collectionVar = Object.values(Object.values(props));
    if (Collectionpageclass && Collectionpageclass.length) {
        Object.keys(Collectionpageclass).map((vardata, index) => {
            const collectionvarid = Collectionpageclass[vardata].dataset.variantId;
            for (let i = 0; i < collectionVar.length; i++) {
                const variant = collectionVar[i];
                const originalPriceGet = productData[i]?.variants[0]?.price / 100;
                const formattedResult = originalPriceGet.toFixed(2);
                let varaiantIdWithDiscount = Object.values(variant)[0];
                if (varaiantIdWithDiscount[collectionvarid] != -1 && (varaiantIdWithDiscount[collectionvarid] || varaiantIdWithDiscount[collectionvarid] == 0)) {
                    let collectionElm = Collectionpageclass[vardata];
                    if (collectionElm && collectionElm.classList.contains('sp-price')) {
                        collectionElm.classList.remove('sp-price');
                    }
                    let price = ShopifyformatMoney(varaiantIdWithDiscount[collectionvarid] * currency, currencyWihoutFormat);
                    let originalPrice = ShopifyformatMoney(formattedResult * currency, currencyWihoutFormat);
                    if (collectionElm) {
                        collectionElm.classList.add('sp-price');
                        collectionElm.innerHTML = Shopify.shop === 'pumbaworkz.myshopify.com' ? `From <span class='sp-original-price' style="text-decoration: line-through";>${originalPrice}</span> ${price} ${Shopify.currency.active}` : price;
                    }
                    collectionElm != undefined ? collectionElm.after(collectionElm) : '';
                }
            }
        });
    }
};

const decryptData = (text) => {
    const atobData = decodeURIComponent(atob(text));
    return atobData;
};

const convertStringToHTML = (htmlString) => {
    let tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;
    return tempDiv.innerText;
}

export { ShopifyformatMoney, isRealValue, getAttribute, ComparePriceRemove, collectionPageDiscountShow, discountedProduct, setDiscountPrice, getAllDomElements, productDiscount, discountCalculation, LocalStorageSetData, LocalStorageGetData, collectionPrice, decryptData, convertStringToHTML, extraFessNormalOrderFun, extraFessDraftOrderFun, extraFeeRuleCheck };
