
import React, { useEffect, useState } from 'react';
import { frontErrorMsg, pdpNote } from '../helper/axios';
import { Table } from './Table';
import { useSelector } from 'react-redux';
import { ShopifyformatMoney, collectionPageDiscountShow, getAllDomElements, isRealValue, discountCalculation, LocalStorageSetData, LocalStorageGetData } from '../helper/Commanfun';

function Volume({ props }) {
    const [priceqty, setpriceqty] = useState();
    const [offerTypes, setOfferType] = useState();
    const [disType, setDisType] = useState();
    const [text,setText] = useState();
    const { security_token, settingData } = useSelector((state) => state.shopInfo);
    let currency = Shopify.currency.rate;
    const pathName = window.location.pathname;
    let Allvariant = ["select[name='id']", "input[name='id']", '.single-option-selector', ".swatch-element input[type='radio']", '.single-option-selector__radio', "select[data-option='option1']", "select[data-option='option1']"];
    let qtySelector = ["input[name='quantity']", 'button.qtybtn'];
    let developerOption = settingData?.developer_option ? JSON.parse(settingData?.developer_option) : null;
    useEffect(() => {
        condition(props);
    }, [text]);

    useEffect(() => {
        let tooltipselector = setInterval(() => {
            let getText = document.querySelectorAll(['.sp-preview-box', '.sp-price-discount']);
            if (isRealValue(getText)) {
                setText(getText);
                clearInterval(tooltipselector);
            }
        },50);
    },[]);

    if (developerOption?.product_page_quantity_selector.trim()) {
        qtySelector.unshift(developerOption?.product_page_quantity_selector);
    }

    const condition = (props) => {
        try {
            const Collectionpageclass = document.querySelectorAll('.specific-pricing');
            let customSelector = Shopify.shop == 'label-guru.myshopify.com' ? 'span.quantity' : '';
            let content = document.querySelector(`[action="/cart/add"] ${customSelector}`);
            let atcSelector = document.querySelector(["div[data-testid='GooglePay-button']", "input[value$='Add to Cart']", '#AddToCart', '.btn--secondary-accent', '.product-form__buttons', '.product-form__add-to-cart.button', '.product-single__cart-submit.shopify-payment-btn btn--secondary', "button[name$='add']", "form[action*='/cart/add'] .product-submit", "form[action='/cart/add'] .btn-hover", "form[action='/cart/add'].add-to-cart-btn", 'button.btn-addtocart, button.tt-btn-addtocart', '.secondary-button', '.btn.product-form__cart-submit.btn--secondary-accent', '.btn.add-to-cart', '.add-to-cart', '#AddToCart--product-template', '.btn--add-to-cart', '.product-form__submit button', "form[action*='/cart/add'] [type=submit]"]);
            let pdpSelector = ['.product-form__buttons','.shopify-payment-button__button', '.btn.add-to-cart', '.btn.product-form__cart-submit'];
            const varid = document.querySelectorAll('select[name="id"],input[name="id"]')[0]?.value;
            if (document.querySelectorAll('.specific-pricing-table-price').length) {
                document.querySelectorAll('.specific-pricing-table-price')[0].remove();
            }
            if (developerOption?.note_on_pdp_selector.trim()) {
                pdpSelector.unshift(developerOption?.note_on_pdp_selector);
                pdpSelector = document.querySelector(pdpSelector);
            } else {
                pdpSelector = document.querySelector(pdpSelector);
            }
            let customer = [];
            let discountData;
            let nonlogin = [];
            let isAllProduct = false;
            Object.keys(props) && Object.keys(props).map((item) => {
                let offerObj = {};
                let offerData = props[item] ? props[item] : {};
                isAllProduct = offerData.apply_to_type == '0' ? true : false;
                let productData = offerData.apply_to_value ? JSON.parse(offerData.apply_to_value) : {};
                let pdpNote = offerData.Note_on_PDP_status == '1' && offerData.Note_on_PDP_Value ? offerData.Note_on_PDP_Value : '';
                if (isRealValue(productData) && Object.keys(productData)) {
                    discountData = JSON.parse(offerData.offer_value);
                    offerObj = { 'offervalue': JSON.parse(offerData.offer_value), 'variantids': productData[meta.product.id], 'discountType': offerData.discount_type, 'userValue': offerData.selected_user_value, 'isAllProduct': isAllProduct, allowProduct: offerData.product_allow_type, 'customText': offerData.custom_text, 'widgetPos': offerData.widget_position, 'customPos': offerData.widget_position_text, 'offerType': offerData.offer_type, pdpNote, 'templeteType' : offerData.templete_type, 'rangeText' : offerData.range_text, 'lastRangeText' : offerData.last_range_text, 'templete_data' : offerData.templete_data, 'final_total' : offerData.final_total };
                    Object.keys(productData).map((data) => {
                        if (offerData.product_allow_type == '1' || data == meta.product.id) {
                            /* 0=> all non login , 1=> all login , 2 => specific customer */
                            if (offerData.selected_user_type == '2') {
                                let userval = JSON.parse(offerData.selected_user_value);
                                if (offerData.customer_allow_type == '0') {
                                    meta.page.customerId && userval.includes(meta.page.customerId.toString()) && customer.push(offerObj);
                                } else {
                                    meta.page.customerId && !userval.includes(meta.page.customerId.toString()) && customer.push(offerObj);
                                }
                            } else if (offerData.selected_user_type == '1') {
                                meta.page.customerId && customer.push(offerObj);
                            } else {
                                nonlogin.push(offerObj);
                            }
                        }
                    });
                } else if (isAllProduct) {
                    discountData = JSON.parse(offerData.offer_value);
                    offerObj = { 'offervalue': JSON.parse(offerData.offer_value), 'discountType': offerData.discount_type, 'userValue': offerData.selected_user_value, 'isAllProduct': isAllProduct, 'customText': offerData.custom_text, 'widgetPos': offerData.widget_position, 'customPos': offerData.widget_position_text, 'offerType': offerData.offer_type, pdpNote, 'templeteType' : offerData.templete_type, 'rangeText' : offerData.range_text, 'lastRangeText' : offerData.last_range_text, 'templete_data' : offerData.templete_data, 'final_total' : offerData.final_total };
                    /* 0=> all non login , 1=> all login , 2 => specific customer */
                    if (offerData.selected_user_type == '2') {
                        let userval = JSON.parse(offerData.selected_user_value);
                        if (offerData.customer_allow_type == '0') {
                            meta.page.customerId && userval.includes(meta.page.customerId.toString()) && customer.push(offerObj);
                        } else {
                            meta.page.customerId && !userval.includes(meta.page.customerId.toString()) && customer.push(offerObj);
                        }
                    } else if (offerData.selected_user_type == '1') {
                        customer.push(offerObj);
                    } else {
                        nonlogin.push(offerObj);
                    }
                } else if (offerData?.catalogData?.length) {
                    offerObj = { 'userValue': offerData.selected_user_value, 'isAllProduct': isAllProduct, allowProduct: offerData.product_allow_type, 'customText': offerData.custom_text, 'widgetPos': offerData.widget_position, 'customPos': offerData.widget_position_text, 'offerType': offerData.offer_type, pdpNote, 'templeteType' : offerData.templete_type,'templete_data' : offerData.templete_data };
                    for (let index = 0; index < offerData.catalogData.length; index++) {
                        let element = offerData.catalogData[index];
                        let productData = JSON.parse(element.products);
                        if (productData[meta.product.id]) {
                            for (const productId in productData) {
                                if (productId == meta.product.id) {
                                    let variantidData = productData[productId];
                                    for (const id in variantidData) {
                                        if (id == varid) {
                                            offerObj['variantids'] = [id];
                                            offerObj['discountType'] = variantidData[id].volume_pricing;
                                            offerObj['offervalue'] = variantidData[id].slab;
                                            offerObj['rangeText'] = variantidData[id].range_text;
                                            offerObj['lastRangeText'] = variantidData[id].last_range_text;
                                        }
                                    }
                                    if (offerData.selected_user_type == '2' || offerData.selected_user_type === null) {
                                        let userval = JSON.parse(offerData.selected_user_value);
                                        if (offerData.customer_allow_type == '0' && userval) {
                                            meta.page.customerId && userval.includes(meta.page.customerId.toString()) && customer.push(offerObj);
                                        } else if (userval) {
                                            meta.page.customerId && !userval.includes(meta.page.customerId.toString()) && customer.push(offerObj);
                                        } else {
                                            customer.push(offerObj);
                                        }
                                    } else if (offerData.selected_user_type == '1') {
                                        meta.page.customerId && customer.push(offerObj);
                                    } else {
                                        nonlogin.push(offerObj);
                                    }
                                }
                            }
                        }
                    }
                } else if (offerData.apply_to_type == '2' && offerData?.tag_product_id && isRealValue(offerData?.tag_product_id)) {
                    let variantIds = meta?.product?.variants?.map((item) => item.id.toString());
                    offerObj = { 'offervalue': JSON.parse(offerData.offer_value), 'variantids': variantIds, 'discountType': offerData.discount_type, 'userValue': offerData.selected_user_value, 'isAllProduct': isAllProduct, allowProduct: offerData.product_allow_type, 'customText': offerData.custom_text, 'widgetPos': offerData.widget_position, 'customPos': offerData.widget_position_text, 'offerType': offerData.offer_type, pdpNote, 'templeteType' : offerData.templete_type, 'rangeText' : offerData.range_text, 'lastRangeText' : offerData.last_range_text, 'templete_data' : offerData.templete_data, 'final_total' : offerData.final_total };
                    offerData?.tag_product_id.map((data) => {
                        if (data == meta.product.id) {
                            /* 0=> all non login , 1=> all login , 2 => specific customer */
                            if (offerData.selected_user_type == '2') {
                                let userval = JSON.parse(offerData.selected_user_value);
                                if (offerData.customer_allow_type == '0') {
                                    meta.page.customerId && userval.includes(meta.page.customerId.toString()) && customer.push(offerObj);
                                } else {
                                    meta.page.customerId && !userval.includes(meta.page.customerId.toString()) && customer.push(offerObj);
                                }
                            } else if (offerData.selected_user_type == '1') {
                                meta.page.customerId && customer.push(offerObj);
                            } else {
                                nonlogin.push(offerObj);
                            }
                        }
                    });
                }
            });
            if (meta?.page.customerId) {
                if (customer.length) {
                    let arr = [];
                    customer.map(async (specCust) => {
                        const templateData = specCust['templete_data'];
                        const templateType = specCust['templeteType'];
                        const rangeText = specCust['rangeText'];
                        const lastRangeText = specCust['lastRangeText'];
                        const qtyandprice = specCust['offervalue'];
                        const variantids = specCust['variantids'] ? specCust['variantids'] : [];
                        const allowProduct = specCust['allowProduct'];
                        const finalTotal = specCust['final_total'];
                        let offerType = specCust['offerType'];
                        let isAllow = true;
                        if (allowProduct == '1') {
                            isAllow = (!variantids.includes(varid) && !arr.includes(varid));
                        } else {
                            isAllow = ((variantids.includes(varid) && !arr.includes(varid)) || (!arr.includes(varid)) && specCust.isAllProduct);
                        }
                        if (isAllow) {
                            arr.push(varid);
                            if (meta.product && meta.product.variants) {
                                let varArr = meta.product.variants;
                                let variantPrice = 0;
                                for (let i = 0; i < varArr.length; i++) {
                                    const variant = varArr[i];
                                    if (varid == variant.id) {
                                        variantPrice = variant.price / 100;
                                    }
                                }
                                if (variantPrice) {
                                    if (qtyandprice) {
                                        let finalQtyTable = discountCalculation(qtyandprice, specCust['discountType'], variantPrice);
                                        const discountType = specCust['discountType'] ? specCust['discountType'] : '';
                                        setDisType(discountType);
                                        setOfferType(offerType);
                                        loadprice(finalQtyTable, discountType, offerType);
                                        setpriceqty(finalQtyTable);
                                        if (isRealValue(finalQtyTable)) {
                                            if (specCust.widgetPos == '0') {
                                                if (content) {
                                                    content && pathName.split('/').indexOf('products') != -1 ? content.insertAdjacentHTML('beforebegin', (Table(finalQtyTable, currency, varid, specCust.customText, offerType, specCust['discountType'], variantPrice, qtySelector, templateType, rangeText, lastRangeText, templateData, finalTotal, text, developerOption))) : '';
                                                } else {
                                                    frontErrorMsg(security_token, Shopify.shop, 'table not show');
                                                }
                                            } else if (specCust.widgetPos == '1') {
                                                if (atcSelector) {
                                                    atcSelector && pathName.split('/').indexOf('products') != -1 ? atcSelector.insertAdjacentHTML('beforeend', (Table(finalQtyTable, currency, varid, specCust.customText, offerType, specCust['discountType'], variantPrice, qtySelector, templateType, rangeText, lastRangeText, templateData, finalTotal, text, developerOption))) : '';
                                                } else {
                                                    frontErrorMsg(security_token, Shopify.shop, 'table not show');
                                                }
                                            } else if (specCust.widgetPos == '2' && specCust.customPos) {
                                                let customSel = document.querySelector(specCust.customPos);
                                                customSel && pathName.split('/').indexOf('products') != -1 ? customSel.insertAdjacentHTML('beforeend', (Table(finalQtyTable, currency, varid, specCust.customText, offerType, specCust['discountType'], variantPrice, qtySelector, templateType, rangeText, lastRangeText, templateData, finalTotal, text, developerOption))) : '';
                                            }
                                        }
                                    }
                                    if (specCust.pdpNote) {
                                        let pdpnote = specCust.pdpNote;
                                        let name = specCust.offerType == 3 ? '{Company_name}' : '{Customer_name}';
                                        if (pdpnote.includes(name)) {
                                            let data = LocalStorageGetData('customer_data');
                                            let customerId = meta.page && meta.page.customerId ? `${meta.page.customerId}` : '';
                                            let customerName = null;
                                            if ((!isRealValue(data) || !data[customerId]) && !text) {
                                                let res = await pdpNote(security_token);
                                                if (res && res.status == 'SUCCESS') {
                                                    let customerData = {};
                                                    if (isRealValue(data)) {
                                                        customerName = res.data.firstName ? res.data.firstName : res.data.company_name;
                                                        data[customerId] = res.data.firstName ? res.data.firstName : res.data.company_name;
                                                        customerData = data;
                                                    } else {
                                                        customerName = res.data.firstName ? res.data.firstName : res.data.company_name;
                                                        customerData[customerId] = res.data.firstName ? res.data.firstName : res.data.company_name;
                                                    }
                                                    LocalStorageSetData('customer_data', customerData);
                                                }
                                                let custName = customerName ? customerName : '';
                                                pdpnote = pdpnote.replaceAll(name, custName);
                                                let SpPDP = document.querySelector('.sp_pdp');
                                                if (SpPDP) {
                                                    pathName.split('/').indexOf('products') != -1 ? SpPDP.innerHTML = pdpnote : '';
                                                } else {
                                                    let htmlPDP = `<span class='sp_pdp'>${pdpnote}</span>`;
                                                    pdpSelector && pathName.split('/').indexOf('products') != -1 ? pdpSelector.insertAdjacentHTML('afterend', htmlPDP) : '';
                                                }
                                            } else {
                                                let custName = data[customerId] ? data[customerId] : '';
                                                pdpnote = pdpnote.replaceAll(name, custName);
                                                let SpPDP = document.querySelector('.sp_pdp');
                                                if (SpPDP) {
                                                    pathName.split('/').indexOf('products') != -1 ? SpPDP.innerHTML = pdpnote : '';
                                                } else {
                                                    let htmlPDP = `<span class='sp_pdp'>${pdpnote}<span/>`;
                                                    pdpSelector && pathName.split('/').indexOf('products') != -1 ? pdpSelector.insertAdjacentHTML('afterend', htmlPDP) : '';
                                                }
                                            }
                                        } else {
                                            let htmlPDP = `<span class='sp_pdp'>${pdpnote}<span/>`;
                                            if (document.querySelector('.sp_pdp')) {
                                                pathName.split('/').indexOf('products') != -1 ? document.querySelector('.sp_pdp').innerHTML = pdpnote : '';
                                            } else {
                                                pdpSelector && pathName.split('/').indexOf('products') != -1 ? pdpSelector.insertAdjacentHTML('afterend', htmlPDP) : '';
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        pathName.includes('collections') && !pathName.includes('products') && collectionPageDiscountShow(Collectionpageclass, qtyandprice, variantid, currency, comparePrice);
                    });
                }
            } else if (nonlogin.length > 0) {
                let arr = [];
                nonlogin.map((nonlogincusitem) => {
                    const templateData = nonlogincusitem['templete_data'];
                    const templateType = nonlogincusitem['templeteType'];
                    const rangeText = nonlogincusitem['rangeText'];
                    const lastRangeText = nonlogincusitem['lastRangeText'];
                    const qtyandprice = nonlogincusitem['offervalue'];
                    const variantids = nonlogincusitem['variantids'] ? nonlogincusitem['variantids'] : [];
                    const allowProduct = nonlogincusitem['allowProduct'];
                    const finalTotal = nonlogincusitem['final_total'];
                    let offerType = nonlogincusitem['offerType'];
                    let isAllow = true;
                    if (allowProduct == '1') {
                        isAllow = (!variantids.includes(varid) && !arr.includes(varid));
                    } else {
                        isAllow = ((variantids.includes(varid) && !arr.includes(varid)) || (!arr.includes(varid)) && nonlogincusitem.isAllProduct);
                    }
                    if (isAllow) {
                        arr.push(varid);
                        if (meta.product && meta.product.variants) {
                            let varArr = meta.product.variants;
                            let variantPrice = 0;
                            for (let i = 0; i < varArr.length; i++) {
                                const variant = varArr[i];
                                if (varid == variant.id) {
                                    variantPrice = variant.price / 100;
                                }
                            }
                            if (variantPrice && qtyandprice) {
                                let finalQtyTable = discountCalculation(qtyandprice, nonlogincusitem['discountType'], variantPrice);
                                const discountType = nonlogincusitem['discountType'] ? nonlogincusitem['discountType'] : '';
                                setDisType(discountType);
                                setpriceqty(finalQtyTable);
                                setOfferType(offerType);
                                loadprice(finalQtyTable, discountType,offerType);
                                if (isRealValue(finalQtyTable)) {
                                    if (nonlogincusitem.widgetPos == '0') {
                                        if (content) {
                                            content && pathName.split('/').indexOf('products') != -1 ? content.insertAdjacentHTML('beforebegin', (Table(finalQtyTable, currency, varid, nonlogincusitem.customText, offerType, nonlogincusitem['discountType'], variantPrice, qtySelector, templateType, rangeText, lastRangeText, templateData, finalTotal, text, developerOption))) : '';
                                        } else {
                                            frontErrorMsg(security_token, Shopify.shop, 'table not show');
                                        }
                                    } else if (nonlogincusitem.widgetPos == '1') {
                                        if (atcSelector) {
                                            atcSelector && pathName.split('/').indexOf('products') != -1 ? atcSelector.insertAdjacentHTML('afterend', (Table(finalQtyTable, currency, varid, nonlogincusitem.customText, offerType, nonlogincusitem['discountType'], variantPrice, qtySelector, templateType, rangeText, lastRangeText, templateData, finalTotal, text, developerOption))) : '';
                                        } else {
                                            frontErrorMsg(security_token, Shopify.shop, 'table not show');
                                        }
                                    } else if (nonlogincusitem.widgetPos == '2' && nonlogincusitem.customPos) {
                                        let customSel = document.querySelector(nonlogincusitem.customPos);
                                        customSel && pathName.split('/').indexOf('products') != -1 ? customSel.insertAdjacentHTML('beforeend', (Table(finalQtyTable, currency, varid, nonlogincusitem.customText, offerType, nonlogincusitem['discountType'], variantPrice, qtySelector, templateType, rangeText, lastRangeText, templateData, finalTotal, text, developerOption))) : '';
                                    }
                                }
                            }
                        }
                    }
                });
            }
        } catch (error) {
            console.log('error=====.', error);
        }
    };

    const buildDiscountPrice = (node, price) => {
        const priceContainer = document.querySelectorAll('.sp-price');
        if (priceContainer && priceContainer.length > 1) {
            if (node) {
                node[0].style.textDecoration = '';
                priceContainer[0].remove();
            }
        }
        if (node) {
            node[0].innerHTML = Shopify.shop === 'pumbaworkz.myshopify.com' ? `${price} ${Shopify.currency.active}` : price;
        }
    };

    const loadprice = (finalQtyTable, disTypes, offerTypes) => {
        try {
            const varid = document.querySelectorAll('select[name="id"],input[name="id"]')[0]?.value;
            let defaultProductPriceSelector = ['.price__sale .specific-pricing-product-page', '.specific-pricing-product-page', '.price__sale .price-item--sale', '.price__regular .price-item','.product__price', '.m-price__regular .m-price-item.m-price-item--regular'];
            if (developerOption?.product_page_price_selector.trim()) {
                defaultProductPriceSelector.unshift(developerOption?.product_page_price_selector);
            }
            setTimeout(() => {
                let qty = document.querySelectorAll("input[name='quantity']")[0]?.value;
                qty = qty ? qty : 1;
                let upperKey = null;
                let lowerKey = null;
                let tables = document.querySelector('.specific-pricing-table-price') && document.querySelector('.specific-pricing-table-price');
                let tableRows = offerTypes != 1 && tables && tables.querySelectorAll(['tbody #sp-row','.sp-preview-card-body .sp-preview-box-main']);
                let wholesaleSelector = document.querySelectorAll('.sp-buy-wrap') && document.querySelectorAll(['.sp-buy-wrap', '.sp-preview-box-main.sp-ws']);
                meta?.product?.variants?.map((item) => {
                    if (varid == item.id) {
                        let priceLine = item.price / 100 * qty;
                        let disflag = false;
                        finalQtyTable && finalQtyTable.map((data, i) => {
                            let fromVal = data['to'] != '' ? data['to'] : qty;
                            if ((parseInt(data['from']) <= qty && (upperKey === null || parseInt(data['from']) >= upperKey) && parseInt(fromVal) >= qty) || (parseInt(data['quantity']) <= qty && (upperKey === null || parseInt(data['quantity']) >= upperKey))) {
                                if (tableRows && tableRows.length) {
                                    for (let i = 0; i < tableRows.length; i++) {
                                        let element = tableRows[i];
                                        let priceRange = element.querySelector('.sp-hidden-range').innerText.split(' ');
                                        let firstRange = priceRange[0].trim();
                                        let temp = priceRange[1] != '' ? priceRange[1].trim() : qty;
                                        if (parseInt(firstRange) <= qty && (lowerKey === null || parseInt(firstRange) >= lowerKey) && parseInt(temp) >= qty) {
                                            lowerKey = parseInt(firstRange);
                                            tableRows.forEach((element) => {
                                                element.classList.remove('sp-active');
                                            });
                                            element.classList.add('sp-active');
                                        }
                                    }
                                } else {
                                    if (isRealValue(wholesaleSelector)) {
                                        if (document.querySelectorAll('.sp-form-check-input').length) {
                                            document.querySelectorAll('.sp-form-check-input')[i].checked = true;
                                        }
                                        wholesaleSelector.forEach((element) => {
                                            element.classList.remove('sp-active');
                                        });
                                        wholesaleSelector[i].classList.add('sp-active');
                                    }
                                }
                                disflag = true;
                                upperKey = parseInt(data['from']) ? parseInt(data['from']) : parseInt(data['quantity']);
                                defaultProductPriceSelector.map(function (classelement) {
                                    setTimeout(() => {
                                        let updatePriceNode = getAllDomElements(classelement, []);
                                        let discount = disTypes == 2 ? (priceLine - data.discount) / qty : data.discount;
                                        buildDiscountPrice(updatePriceNode, ShopifyformatMoney(discount * currency, currencyWihoutFormat));
                                    }, 1000);
                                });
                            }
                        });
                        if (!disflag) {
                            let priceLine = item.price / 100;
                            if (document.querySelector('.sp-active')) {
                                defaultProductPriceSelector.map(function (classelement) {
                                    setTimeout(() => {
                                        let updatePriceNode = getAllDomElements(classelement, []);
                                        buildDiscountPrice(updatePriceNode, ShopifyformatMoney(priceLine, currencyWihoutFormat));
                                    }, 1000);
                                });
                            }
                            tableRows && tableRows.forEach((element) => {
                                element.classList.remove('sp-active');
                            });
                            wholesaleSelector && wholesaleSelector.forEach((ele, index) => {
                                if (document.querySelectorAll('.sp-form-check-input').length) {
                                    document.querySelectorAll('.sp-form-check-input')[index].checked = false;
                                }
                                ele.classList.remove('sp-active');
                            });
                        }
                    }
                });
            }, 1000);
            // }
        } catch (error) {
            console.log('error---->', error);
        }
    };

    useEffect(() => {
        if (isRealValue(props)) {
            addCustomEventListener(Allvariant, 'change', function () {
                if (pathName.split('/').indexOf('products') != -1 || pathName.split('/').indexOf('collections') != -1) {
                    setTimeout(() => {
                        condition(props);
                    });
                }
            });
        }
    }, [props]);

    useEffect(() => {
        if (priceqty) {
            ['change', 'click'].forEach((event) => {
                addCustomEventListener(qtySelector, event, function () {
                    loadprice(priceqty, disType, offerTypes);
                });
            });
        }
    }, [priceqty]);

    return (
        <>
        </>
    );
}

export default Volume;