import { ShopifyformatMoney, isRealValue } from '../helper/Commanfun';
import './Table.css';
import React from 'react';
function Table(qtyandprice, currency, varid, customText, offerType, discountType, productOriginalPrice, qtySelector, templateType, rangeText, lastRangeText, templateData, finalTotal, text, developerOption) {
    let textElm = '';
    let qtyTitle = '';
    let tableTxt = '';
    let tableTitle = templateData && typeof templateData === 'string' ? JSON.parse(templateData) : templateData;
    if (tableTitle && tableTitle.volume_b2b_d2c) {
        qtyTitle = discountType == 0 ? tableTitle.volume_b2b_d2c.percentage_text : discountType == 1 ? tableTitle.volume_b2b_d2c.amount_text : tableTitle.volume_b2b_d2c.flat_rate_discount_text;
        tableTxt = discountType == 0 ? tableTitle.volume_b2b_d2c.percentage : discountType == 1 ? tableTitle.volume_b2b_d2c.amount : tableTitle.volume_b2b_d2c.flat_rate_discount;
    }
    if (customText) {
        textElm = document.createElement('span');
        textElm.className = 'specific-text';
        textElm.innerHTML = customText;
        textElm = typeof textElm === 'string' ? textElm : textElm.outerHTML;
    }
    const createHtml = () => {
        let tableHtml = '';
        if (qtyandprice.length) {
            for (let i = 0; i < qtyandprice.length; i++) {
                let tooltiptext = isRealValue(text) && text[i] && text[i].querySelector(['.sp-preview-box-label', '.sp-volumeSaveBadge']) ? text[i].querySelector(['.sp-preview-box-label', '.sp-volumeSaveBadge']).innerText : '';
                const element = qtyandprice[i];
                const badgeText = element?.badge_text?.length ? element.badge_text : '';
                let discountText = element?.discount_text ? element.discount_text : '';
                if (offerType == 0 || offerType == 2 || offerType == 3) {
                    const toVal = element.to ? element.to : '';
                    const fromVal = element.from ? element.from : '';
                    const tofromval = toVal.trim() ? toVal : fromVal;
                    let flatdiscount = discountType == 2 && (productOriginalPrice * tofromval) - element.discount;
                    flatdiscount = flatdiscount && flatdiscount / tofromval;
                    let flatDiscountCal = element.discount / (productOriginalPrice * fromVal) * 100;
                    let percentageCal = discountType == 2 ? `${Math.floor(element.discount / (productOriginalPrice * fromVal) * 100)}%` : `${Math.round(((productOriginalPrice - element.discount) / productOriginalPrice) * 100)}%`;
                    percentageCal = discountType == 2 && Math.floor(flatDiscountCal) == 0 ? `${flatDiscountCal.toFixed(2)}%` : percentageCal;
                    let amountCal = discountType == 2 ? ShopifyformatMoney(Math.round(element.discount), currencyWihoutFormat) : ShopifyformatMoney(Math.round(productOriginalPrice - element.discount), currencyWihoutFormat);
                    let rangeTexts = rangeText?.length ? rangeText.replaceAll('{{to_value}}', toVal).replaceAll('{{from_value}}', fromVal).replaceAll('{{percentage}}', percentageCal).replaceAll('{{amount}}', amountCal) : '';
                    let lastRangeTexts = null;
                    if (discountText.includes('{{percentage}}') || discountText.includes('{{amount}}')) {
                        discountText = discountText.replaceAll('{{percentage}}', percentageCal).replaceAll('{{amount}}', amountCal);
                    } else {
                        discountText = discountText;
                    }
                    if (lastRangeText && qtyandprice.length != 1 && (qtyandprice.length - 1) === i) {
                        lastRangeTexts = lastRangeText.replaceAll('{{from_value}}', fromVal).replaceAll('{{percentage}}', percentageCal).replaceAll('{{amount}}', amountCal).replaceAll('{{to_value}}', toVal);
                    }
                    if (templateType === '0') {
                        tableHtml += `<tr id='sp-row' style="cursor:pointer";>
                            <td style="border: 1px solid ${tableTitle?.slab_box_border_color}" class="sp-range"><span class='sp-hidden-range'>${fromVal} ${toVal}</span>${badgeText && badgeText.trim().length ? `<span class="sp-volumePopularBadge" title="${badgeText}" style="cursor: pointer; background: ${tableTitle?.badge_bg_color}; color: ${tableTitle?.badge_text_color};">${badgeText}</span>` : ''}<br><span>${lastRangeTexts ? lastRangeTexts : rangeTexts}</span></td>
                            <td style="border: 1px solid ${tableTitle?.slab_box_border_color}"><span class="sp-price-discount"><span class='sp-hidden-price'>${discountType == 2 ? ShopifyformatMoney(flatdiscount * currency, currencyWihoutFormat) : ShopifyformatMoney(element.discount * currency, currencyWihoutFormat)}</span>${discountText && discountText.trim().length ? `<span class="sp-volumeSaveBadge" title="${tooltiptext}" style="background: ${tableTitle?.save_text_bg_color}; color: ${tableTitle?.save_text_color};">${discountText}</span>` : ''}<br> <span>${ShopifyformatMoney(element.discount * currency, currencyWihoutFormat)}</span></span></td>
                        </tr>`;
                    } else if (templateType === '1') {
                        tableHtml += `<li class="sp-preview-box-main" style="border: 1px solid ${tableTitle?.slab_box_border_color}; cursor:pointer">
                        <div class="sp-preview-box">
                        ${badgeText && badgeText.trim().length ?
                                `<div class="sp-preview-box-badge" style="background: ${tableTitle?.badge_bg_color}; cursor: pointer" title="${badgeText}"><span style="color: ${tableTitle?.badge_text_color}">${badgeText}</span></div>`
                                : ''
                            }
                            <span class='sp-hidden-range'>${fromVal} ${toVal}</span>
                            <span class='sp-hidden-price'>${discountType == 2 ? ShopifyformatMoney(flatdiscount * currency, currencyWihoutFormat) : ShopifyformatMoney(element.discount * currency, currencyWihoutFormat)}</span>
                            <h2 class="sp-preview-box-text">${lastRangeTexts ? lastRangeTexts : rangeTexts}</h2>
                            ${discountText && discountText.trim().length ?
                                `<div class="sp-preview-box-label" style="background: ${tableTitle?.save_text_bg_color}; border: 1px solid ${tableTitle?.save_text_box_border_color}; cursor: pointer" title='${tooltiptext}'>
                                    <p style="color: ${tableTitle?.save_text_color}">${discountText}</p>
                                </div>`
                                : ''
                            }
                        </div>
                    </li>`;
                    }
                } else {
                    const toVal = element.quantity;
                    const wholesaleDiscount = discountType == 2 ? (productOriginalPrice * toVal - element.discount) / toVal : element.discount * currency;
                    let flatDiscountCal = element.discount / (productOriginalPrice * toVal) * 100;
                    let percentageCal = discountType == 2 ? `${Math.floor(element.discount / (productOriginalPrice * toVal) * 100)}%` : `${Math.round(((productOriginalPrice - element.discount) / productOriginalPrice) * 100)}%`;
                    percentageCal = discountType == 2 && Math.floor(flatDiscountCal) == 0 ? `${flatDiscountCal.toFixed(2)}%` : percentageCal;
                    let amountCal = discountType == 2 ? ShopifyformatMoney(Math.round(element.discount), currencyWihoutFormat) : ShopifyformatMoney(Math.round((productOriginalPrice - element.discount) * toVal), currencyWihoutFormat);
                    if (discountText.includes('{{percentage}}') || discountText.includes('{{amount}}')) {
                        discountText = discountText.replaceAll('{{percentage}}', percentageCal).replaceAll('{{amount}}', amountCal);
                    } else {
                        discountText = discountText;
                    }
                    let rangeTexts = rangeText?.length ? rangeText.replaceAll('{{quantity}}', toVal).replaceAll('{{percentage}}', percentageCal).replaceAll('{{amount}}', amountCal) : '';
                    let lastRangeTexts = null;
                    if (lastRangeText && qtyandprice.length != 1 && (qtyandprice.length - 1) === i) {
                        lastRangeTexts = lastRangeText.replaceAll('{{quantity}}', toVal).replaceAll('{{percentage}}', percentageCal).replaceAll('{{amount}}', amountCal);
                    }
                    if (templateType === '5') {
                        tableHtml += `<div style="border : 1px solid ${tableTitle?.slab_box_border_color}" class="sp-buy-wrap">
                        ${badgeText && badgeText.trim().length ?
                                `<div class="sp-wholesaleBestPriceBadge" style="background: ${tableTitle?.badge_bg_color};" title="${badgeText}"><span  style="color: ${tableTitle?.badge_text_color};">${badgeText}</span></div>`
                                : ''}
                        <div class="sp-allprice">
                             <div class="sp-buy-qty">
                                 <input class="sp-form-check-input" type="radio" name="sp-demo" id="sp-radio"/>
                                 ${lastRangeTexts ? lastRangeTexts : rangeTexts}
                             </div>
                             <div class="sp-price-section">
                                 <p class="sp-product-price"><span class="sp-original-price">${wholesaleDiscount != productOriginalPrice ? ShopifyformatMoney(productOriginalPrice * currency, currencyWihoutFormat) : ''}</span><span style="font-weight:600;">${ShopifyformatMoney(wholesaleDiscount * currency, currencyWihoutFormat)}</span></p>
                                 <p class="sp-final-price"><span class="sp-total">${finalTotal ? finalTotal : ''} ${ShopifyformatMoney(wholesaleDiscount * toVal, currencyWihoutFormat)}</span></p>
                                 ${discountText && discountText.trim().length ?
                                `<div style="color:${tableTitle?.save_text_color};" class="sp-wholesaleTempletSaveBadge">${discountText}</div>`
                                : ''}
                             </div>
                             </div>
                         </div>`;
                    } else if (templateType === '6') {
                        tableHtml += `<li class="sp-preview-box-main sp-ws" style="border: 1px solid ${tableTitle?.slab_box_border_color}; cursor:pointer">
                        <div class="sp-preview-box">
                        ${badgeText && badgeText.trim().length ?
                                `<div class="sp-preview-box-badge" style="background: ${tableTitle?.badge_bg_color}; cursor: pointer" title="${badgeText}"><span  style="color:${tableTitle?.badge_text_color};">${badgeText}</span></div>`
                                : ''
                            }
                            <h2 class="sp-preview-box-text">${lastRangeTexts ? lastRangeTexts : rangeTexts}</h2>
                            ${discountText && discountText.trim().length ?
                                `<div class="sp-preview-box-label" style="background: ${tableTitle?.save_text_bg_color}; border: 1px solid ${tableTitle?.save_text_box_border_color}">
                                <p style="color: ${tableTitle?.save_text_color}; cursor: pointer" title="${tooltiptext}">${discountText}</p>
                            </div>`
                                : ''
                            }
                        </div>
                    </li>`;
                    }
                }
                let wholesaleb2bInterval = setInterval(() => {
                    let selectorGet = document.querySelectorAll(['#sp-row', '.sp-buy-wrap', '.sp-preview-box-main', '.sp-preview-box-main.sp-ws']);
                    if (isRealValue(selectorGet)) {
                        let element = document.querySelectorAll('.sp-wholesaleBestPriceBadge');
                        for (let index = 0; index < selectorGet.length; index++) {
                            if (element[index]) {
                                const heightGet = element[index].getBoundingClientRect();
                                if (heightGet.height > 25) {
                                    element[index].classList.add('sp-badge-wrap-center');
                                } else if (document.querySelectorAll('.sp-badge-wrap-center')[index]) {
                                    element[index].classList.remove('sp-badge-wrap-center');
                                }
                            }
                            selectorGet[index] && selectorGet[index].addEventListener('click', function () {
                                let qtyPicker = document.querySelector(qtySelector);
                                let selectedPrice = offerType == 0 || offerType == 2 || offerType == 3 ? document.querySelectorAll('.sp-hidden-range')[index].innerHTML.split(' ') : discountType == 2 ? (productOriginalPrice * qtyandprice[index].quantity - qtyandprice[index].discount) / qtyandprice[index].quantity : qtyandprice[index].discount;
                                let price = offerType == 0 || offerType == 2 || offerType == 3 ? document.querySelectorAll('.sp-hidden-price')[index].innerText : ShopifyformatMoney(selectedPrice * currency, currencyWihoutFormat);
                                let defaultProductPriceSelector = ['.price__sale .specific-pricing-product-page', '.specific-pricing-product-page', '.price__sale .price-item--sale', '.price__regular .price-item','.product__price', '.m-price__regular .m-price-item.m-price-item--regular'];
                                if (developerOption?.product_page_price_selector.trim()) {
                                    defaultProductPriceSelector.unshift(developerOption?.product_page_price_selector);
                                    defaultProductPriceSelector = document.querySelectorAll(defaultProductPriceSelector);
                                } else {
                                    defaultProductPriceSelector = document.querySelectorAll(defaultProductPriceSelector);
                                }
                                if (document.querySelectorAll('.sp-form-check-input').length) {
                                    offerType == 1 ? document.querySelectorAll('.sp-form-check-input')[index].checked = true : '';
                                }
                                selectorGet.forEach((element) => {
                                    element.classList.remove('sp-active');
                                });
                                selectorGet[index].classList.add('sp-active');
                                if (qtyPicker) {
                                    offerType == 0 || offerType == 2 || offerType == 3 ? qtyPicker.value = selectedPrice[1] ? selectedPrice[1].trim() : selectedPrice[0].trim() : qtyPicker.value = qtyandprice[index].quantity;
                                }
                                defaultProductPriceSelector[0] ? defaultProductPriceSelector[0].innerHTML = price : '';
                                defaultProductPriceSelector[1] ? defaultProductPriceSelector[1].innerHTML = price : '';
                            });
                        }
                        clearInterval(wholesaleb2bInterval);
                    }
                }, 1000);
            }
        }
        return tableHtml;
    };

    if (offerType == 0 || offerType == 2 || offerType == 3) {
        if (templateType === '0') {
            let datatable =
                `<div class="specific-pricing-table-price specific-pricing-table-price-${varid}">
                    ${textElm}
                        <table class="table" style="width: 100%;">
                            <thead>
                                <tr style="background: ${tableTitle?.header_text_bg_color};">
                                    <th style="border: 1px solid ${tableTitle?.slab_box_border_color}; color: ${tableTitle?.header_text_color}">${tableTxt}</th>
                                    <th style="border: 1px solid ${tableTitle?.slab_box_border_color}; color: ${tableTitle?.header_text_color}">${qtyTitle}</th>
                                </tr>
                            </thead>
                            <tbody> 
                                ${createHtml()}
                            </tbody>
                        </table>
                </div>`;
            return datatable;
        } else if (templateType === '1') {
            let datatable =
                ` <section class="sp-preview-card-section">
                <div class="specific-pricing-table-price specific-pricing-table-price-${varid}">
            <div class="sp-preview-card-body">
            <p class="sp-preview-card-body-text">${textElm}</p>
                <ul class="sp-preview-card-list">
                ${createHtml()}
                </ul>
                </div>
            </div>
        </section>`;
            return datatable;
        }
    } else {
        if (templateType === '5') {
            let datatable =
                `<div class="specific-pricing-table-price specific-pricing-table-price-${varid}">
                ${textElm}
                ${createHtml()}
                </div>`;
            return datatable;
        } else if (templateType === '6') {
            let datatable =
                ` <section class="sp-preview-card-section">
                <div class="specific-pricing-table-price specific-pricing-table-price-${varid}">
        <div class="sp-preview-card-body">
        <p class="sp-preview-card-body-text">${textElm}</p>
            <ul class="sp-preview-card-list">
            ${createHtml()}
            </ul>
        </div>
        </div>
    </section>`;
            return datatable;
        }
    }
}
export { Table };