
import React, { useEffect } from 'react';
import { collectionPrice, isRealValue } from '../helper/Commanfun';
import { useSelector } from 'react-redux';

function Collections({ props, searchData}) {
    const {settingData} = useSelector((state) => state.shopInfo);
    let DeveloperOptionData = settingData?.developer_option;
    let dataParse = DeveloperOptionData ? JSON.parse(DeveloperOptionData) : '';
    let currency = Shopify.currency.rate;
    useEffect(() => {
        collectionPageDiscountShow();
    }, []);

    const collectionPageDiscountShow = () => {
        try {
            const Collectionpageclass = document.querySelectorAll('.specific-pricing');
            const productData = meta.products ? meta.products : meta.page.pageType === "searchresults" ? searchData.products : [];
            if (Collectionpageclass && Collectionpageclass.length) {
                collectionPrice(props, currency, Collectionpageclass, productData);
                ComparePriceRemove();
            } else {
                let collectionPageArr = ['.collection .price', '.sf__pcard-price', '#Collection .price'];
                if (dataParse?.collection_page_price && dataParse?.collection_page_price.trim().length != 0) {
                    if (!(/^(#\d|\.\d)/.test(dataParse?.collection_page_price))) {
                        collectionPageArr = dataParse?.collection_page_price;
                    }
                }
                let collectionPageclass = document.querySelectorAll(collectionPageArr);
                // let collectionProduct = meta.products ? meta.products : meta.page.pageType === "searchresults" ? searchData.products : [];
                let discountProductId = Object.values(props);
                if (collectionPageclass && collectionPageclass.length && productData.length) {
                    for (let i = 0; i < collectionPageclass.length; i++) {
                        const element = collectionPageclass[i];
                        let variantId = productData[i]?.variants[0]?.id;
                        for (let index = 0; index < discountProductId.length; index++) {
                            const productWiseId = discountProductId[index];
                            if (productWiseId[productData[i].id] && productWiseId[productData[i].id][variantId] != -1) {
                                if (productData[i]?.id == Object.keys(productWiseId)) {
                                    let spElem = `<span class="specific-pricing" data-variant-id="${variantId}" data-variant-price=""></span>`;
                                    element.insertAdjacentHTML('beforeend', spElem);
                                    collectionPageclass[i].innerHTML = spElem;
                                }
                            }
                        }
                    }
                }
                const Collectionpageclass = document.querySelectorAll('.specific-pricing');
                if (Collectionpageclass && Collectionpageclass.length) {
                    collectionPrice(props,currency,Collectionpageclass,productData);
                }

            }
        } catch (error) {
            console.log('error--------', error);
        }
    };

    const ComparePriceRemove = () => {
        const priceContainer = document.querySelectorAll('.sp-price');
        priceContainer && Object.keys(priceContainer).map((vardata) => {
            let comparePriceRemove = priceContainer[vardata].parentNode;
            let comparePriceNode = comparePriceRemove ? comparePriceRemove.querySelector('s') : '';
            comparePriceNode ? comparePriceNode.style.display = 'none' : '';
        });
    };

    return (
        <></>
    );
}

export default Collections;