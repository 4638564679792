import { configureStore } from '@reduxjs/toolkit';
import cartInfo from './redux_slices/cart.slice';
import shopInfo from './redux_slices/shop.slice';

const store = configureStore({
    reducer: {
         cartInfo,
         shopInfo
    }
});
export default store;