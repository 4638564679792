import axios from 'axios';
import { LocalStorageGetData, LocalStorageSetData, getAttribute, isRealValue, productDiscount } from './Commanfun';

let headers = {
    'Content-Type': 'application/json'
};

const ApiCall = async (method, path, payload, security_token) => {
    try {
        if (security_token) {
            headers['authentication'] = security_token;
            payload['security_token'] = security_token;
        }else{
            payload['security_token'] = 'No token';
        }
        if (isRealValue(headers) && !(headers.hasOwnProperty('authentication'))) {
            collectionPageError(headers, Shopify.shop, payload.method_name);
        }
        let responce = await axios.post(process.env.NODE_BASE_URL + path, payload, { headers: headers });
        return responce;
    } catch (error) {
        console.log('axiosApiCall-----==', error);
        if (error.message === 'Network Error') {
            console.log(`${error}, Server is not responding, please try again after some time`);
        }
        if (error.response?.data?.statusCode === 401 && headers && !headers['access-token']) {
            if (error.response.data.refresh_expire) {
                return error.response;
            }
        } else {
            return error.response;
        }
    }
};

const CartDataGet = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            let response = '';
            response = await axios.get('/cart.js', {});
            resolve(response);
        } catch (error) {
            console.log('axiosApiCall-----==', error);
        }
    });
};

const DraftOder = async (discount, price_data, shop_info_data, security_token, AddressGet, settingData, subtotal_discount_value,  extraFee_data, extraFeesProduct) => {
    let CheckoutUrl = 'https://' + window.location.hostname + '/checkout';
    try {
        let prepared_data = {};
        let discountAmt = [];
        const cart_response = await axios.get(`/cart.js?currency=${specificShopCurrency}`, {});
        if (cart_response.status == 200 && cart_response.data?.items) {
            let productData = cart_response.data;
            let subTotal = productData.total_price / 100;
            let original_subTotal = subTotal;
            let minMaxDiscountValue = true;
            let cartCount = cart_response.data.items.length;
            cartCount -= 1;
            let shopify_general_discount = productData?.cart_level_discount_applications;
            // let TotalDiscouts = 0;
            productData?.items?.map((item) => {
                let shopifyDiscountCheck = settingData?.apply_discount_type != 0 ? !isRealValue(item?.line_level_discount_allocations) : true;
                let priceLine = (settingData?.order_type == 0 || settingData?.order_type == 1) && item.selling_plan_allocation && shopifyDiscountCheck ? item.selling_plan_allocation?.compare_at_price / 100 * item.quantity : settingData?.apply_discount_type === '0' ? item.original_line_price / 100 : item.final_line_price / 100;
                let combinationPrice = settingData?.order_type == 2 ? settingData?.apply_discount_type != 0 ? item.final_line_price / 100 : item.original_line_price / 100 : false;
                let lineItem = item.final_line_price / 100;
                let both_discount_applied = settingData?.both_discount_applied;
                let combinationWithSubscription = settingData?.order_type == 2 && item.selling_plan_allocation ? Number(both_discount_applied) : 1;
                let discountType = null;
                let discountAmount;
                let slabId = [];
                let productVariant = [];
                let extraFeesProductCheck = isRealValue(extraFeesProduct) ? !extraFeesProduct[item.product_id] : true;
                let shopify_LineItem_discount = item?.line_level_discount_allocations;
                if (extraFeesProductCheck) {
                    for (let index = 0; index < price_data.length; index++) {
                        let tableDataVariantId = price_data[index];
                        let productVariantId = tableDataVariantId.apply_to_value ? JSON.parse(tableDataVariantId.apply_to_value) : '';
                        let product_variants_ids = [];
                        if (tableDataVariantId?.apply_to_type == '2' && tableDataVariantId?.tag_product_id && isRealValue(tableDataVariantId?.tag_product_id)) {
                            product_variants_ids = productData?.items?.map((item) =>  tableDataVariantId?.tag_product_id.includes(item?.product_id.toString()) && item.variant_id.toString()).filter(Boolean);
                        }
                        let discountProductVariantId = isRealValue(productVariantId) ? [].concat(...Object.values(productVariantId)) : product_variants_ids;
                        let offerValue = JSON.parse(tableDataVariantId.offer_value);
                        let cartValueMinMax = JSON.parse(tableDataVariantId.cart_value);
                        if (!(productVariant.includes(item.id)) && combinationWithSubscription) {
                            if (tableDataVariantId?.catalogData?.length) {
                                for (let index = 0; index < tableDataVariantId.catalogData.length; index++) {
                                    const element = tableDataVariantId.catalogData[index];
                                    let productData = JSON.parse(element.products);
                                    for (const productkey in productData) {
                                        let variantData = productData[productkey];
                                        for (const varaiantId in variantData) {
                                            if (varaiantId == item.id && !(slabId.includes(item.id))) {
                                                slabId.push(item.id);
                                                let discountData = variantData[varaiantId];
                                                discountProductVariantId.push(varaiantId);
                                                discountAmount = isRealValue(discountData) && productDiscount(item.quantity, discountData.slab, 'true', discountData.volume_pricing, item.price / 100, true, tableDataVariantId?.offer_type);
                                                discountType = discountData.volume_pricing;
                                            }
                                        }
                                    }
                                }
                            }
                            let exclusive_Inclusive = tableDataVariantId.product_allow_type == 1 ? !(discountProductVariantId.includes(item.id.toString())) : discountProductVariantId.includes(item.id.toString());
                            if (exclusive_Inclusive) {
                                productVariant.push(item.id);
                                let temp = [];
                                if (tableDataVariantId?.offer_type == 0 || tableDataVariantId?.offer_type == 1) {
                                    let price = item.selling_plan_allocation ? item.selling_plan_allocation?.compare_at_price / 100 : item.price / 100;
                                    discountAmount = productDiscount(item.quantity, offerValue, 'false', tableDataVariantId.discount_type, price, true, tableDataVariantId?.offer_type);
                                }
                                if ((discountAmount || discountAmount === 0) && priceLine > 0) {
                                    let finalPrice = original_subTotal;
                                    let minCartValue = cartValueMinMax.Min != '' ? cartValueMinMax.Min : finalPrice;
                                    let maxCartValue = cartValueMinMax.Max != '' ? cartValueMinMax.Max : finalPrice;
                                    minMaxDiscountValue = finalPrice >= minCartValue && finalPrice <= maxCartValue;
                                    if (tableDataVariantId.discount_type == 0 || discountType == 0) {
                                        discountAmount = discountAmount <= 100 ? (((combinationPrice ? combinationPrice : priceLine) * discountAmount / 100)) : 0;
                                    }
                                    if (tableDataVariantId.discount_type == 1 || discountType == 1) {
                                        if (combinationPrice) {
                                            discountAmount = discountAmount < combinationPrice ? combinationPrice - discountAmount : false;
                                        } else {
                                            discountAmount = discountAmount <= priceLine ? priceLine - discountAmount : false;
                                        }
                                    }
                                    if (tableDataVariantId.discount_type == 2 || discountType == 2) {
                                        if (combinationPrice) {
                                            discountAmount = discountAmount < combinationPrice ? discountAmount : false;
                                        } else {
                                            discount = discount < priceLine ? discount : false;
                                        }
                                    }
                                    discountAmount = minMaxDiscountValue ? discountAmount : false;
                                    if ((discountAmount || discountAmount === 0) && minMaxDiscountValue) {
                                        let prepared_obj = {
                                            product_id: item.product_id,
                                            variant_id: item.variant_id,
                                            discount_value: Number(discountAmount),
                                            product_quantity: item.quantity,
                                            offer_type: tableDataVariantId.offer_type,
                                            offer_id: tableDataVariantId.id,
                                            discount_title: tableDataVariantId.offer_name,
                                            discount_type: "0"
                                        };
                                        discountAmt.push(discountAmount);
                                        temp.push(prepared_obj);
                                        prepared_data[cartCount] = temp;
                                        original_subTotal = tableDataVariantId.discount_type == 1 || discountType == 1 ? finalPrice - (priceLine - discountAmount) : finalPrice - discountAmount;
                                    }
                                }
                                // /* else if (discountAmount && lineItem == 0) {
                                //     let bogoDiscountPrice = item.original_line_price / 100;
                                //     let finalBogoDiscount = bogoDiscountPrice - lineItem;
                                //     let prepared_obj = {
                                //         product_id: item.product_id,
                                //         variant_id: item.variant_id,
                                //         discount_value: finalBogoDiscount,
                                //         product_quantity: item.quantity
                                //     };
                                //     discountAmt.push(discountAmount);
                                //     temp.push(prepared_obj);
                                //     prepared_data[cartCount] = temp;
                                // } */
                            }
                        }
    
                        if (tableDataVariantId.apply_to_value === null && combinationWithSubscription && tableDataVariantId.apply_to_type == 0 && !(productVariant.includes(item.id))) {
                            let temp = [];
                            productVariant.push(item.id);
                            let price = item.selling_plan_allocation ? item.selling_plan_allocation?.compare_at_price / 100 : item.price / 100;
                            discountAmount = productDiscount(item.quantity, offerValue, 'false', tableDataVariantId.discount_type, price, true, tableDataVariantId?.offer_type);
                            if ((discountAmount || discountAmount === 0) && priceLine > 0) {
                                let finalPrice = original_subTotal;
                                let minCartValue = cartValueMinMax.Min != '' ? cartValueMinMax.Min : finalPrice;
                                let maxCartValue = cartValueMinMax.Max != '' ? cartValueMinMax.Max : finalPrice;
                                minMaxDiscountValue = finalPrice >= minCartValue && finalPrice <= maxCartValue;
                                if (tableDataVariantId.discount_type == 0) {
                                    discountAmount = discountAmount <= 100 ? (((combinationPrice ? combinationPrice : priceLine) * discountAmount / 100)) : 0;
                                }
                                if (tableDataVariantId.discount_type == 1) {
                                    if (combinationPrice) {
                                        discountAmount = discountAmount < combinationPrice ? combinationPrice - discountAmount : false;
                                    } else {
                                        discountAmount = discountAmount <= priceLine ? priceLine - discountAmount : false;
                                    }
                                }
                                if (tableDataVariantId.discount_type == 2) {
                                    if (combinationPrice) {
                                        discountAmount = discountAmount < combinationPrice ? discountAmount : false;
                                    } else {
                                        discount = discount < priceLine ? discount : false;
                                    }
                                }
                                discountAmount = minMaxDiscountValue ? discountAmount : false;
                                if ((discountAmount || discountAmount === 0) && minMaxDiscountValue) {
                                    let prepared_obj = {
                                        product_id: item.product_id,
                                        variant_id: item.variant_id,
                                        discount_value: Number(discountAmount),
                                        product_quantity: item.quantity,
                                        offer_type: tableDataVariantId.offer_type,
                                        offer_id: tableDataVariantId.id,
                                        discount_title: tableDataVariantId.offer_name,
                                        discount_type: "0"
                                    };
                                    discountAmt.push(discountAmount);
                                    temp.push(prepared_obj);
                                    prepared_data[cartCount] = temp;
                                    original_subTotal = tableDataVariantId.discount_type == 1 ? finalPrice - (priceLine - discountAmount) : finalPrice - discountAmount;
                                }
                            }
                            // /* else if (discountAmount && lineItem == 0) {
                            //     let bogoDiscountPrice = item.original_line_price / 100;
                            //     let finalBogoDiscount = bogoDiscountPrice - lineItem;
                            //     let prepared_obj = {
                            //         product_id: item.product_id,
                            //         variant_id: item.variant_id,
                            //         discount_value: finalBogoDiscount,
                            //         product_quantity: item.quantity
                            //     };
                            //     discountAmt.push(discountAmount);
                            //     temp.push(prepared_obj);
                            //     prepared_data[cartCount] = temp;
                            // } */
                        }
                    }
                }
                if (isRealValue(shopify_LineItem_discount) && settingData?.order_type != 2 && settingData?.apply_discount_type !== '0') {
                    let prepared_obj = {};
                    shopify_LineItem_discount.forEach((element) => {
                        prepared_obj = {
                            product_id: item.product_id,
                            variant_id: item.variant_id,
                            product_quantity: item.quantity,
                            discount_value: element.amount / 100,
                            discount_title: element?.discount_application?.title,
                            discount_type: "1"
                        };
                    });
                    prepared_data[cartCount] ? prepared_data[cartCount].push(prepared_obj) : prepared_data[cartCount] = [prepared_obj];
                }
                if (isRealValue(shopify_general_discount) && settingData?.order_type != 2 && settingData?.apply_discount_type !== '0') {
                    let prepared_obj = {};
                    shopify_general_discount.forEach((element) => {
                        let general_discount_value = ((element.total_allocated_amount / 100) / subtotal_discount_value) * 100;
                        let discount_price = priceLine > discountAmount ? priceLine - discountAmount : priceLine;
                        let general_discount_cal = element.value_type === 'percentage' ? (discount_price * Number(element.value) / 100).toFixed(2) : (general_discount_value / 100 * discount_price).toFixed(2);
                        prepared_obj = {
                            product_id: item.product_id,
                            variant_id: item.variant_id,
                            product_quantity: item.quantity,
                            discount_value: Number(general_discount_cal),
                            discount_title: element?.title,
                            discount_type: "2"
                        };
                        prepared_data[cartCount] ? prepared_data[cartCount].push(prepared_obj) : prepared_data[cartCount] = [prepared_obj];
                    });
                }
                cartCount = cartCount - 1;
                if (settingData?.apply_discount_type == '1' && (isRealValue(shopify_LineItem_discount) || shopify_general_discount.length > 1)) {
                    discountAmt = [];
                }
            });
            let discountAmtCommon = 0;
            if (settingData?.order_type === '2') {
                if (isRealValue(discountAmt) && discountAmt.every((value, index, array) => value === array[0])) {
                    discountAmtCommon = 1;
                } else {
                    discountAmtCommon = 0;
                }
            }
            const noteAttribute = getAttribute();
            const noteAttr = [];
            if (isRealValue(noteAttribute) && Object.keys(noteAttribute).length) {
                for (let attrKey in noteAttribute) {
                    noteAttribute[attrKey] && noteAttr.push({ 'name': attrKey, 'value': noteAttribute[attrKey] });
                }
            }
            let orderNote = document.querySelector('[name="note"]');
            orderNote = orderNote ? orderNote.value : '';
            const method_name = settingData?.order_type === '0' || settingData?.order_type === '1' ? 'createDraft' : 'createNormal';
            let payload = {
                attribute: prepared_data,
                method_name: method_name,
                shopInfo: shop_info_data,
                note_attribute: noteAttr,
                order_note: orderNote,
                shipping_address: AddressGet,
                combined_discount: settingData?.order_type === '2' && settingData?.apply_discount_type === '1' ? settingData?.apply_discount_type : '0',
                shopify_discount_type: settingData?.order_type === '2' ? discountAmtCommon : undefined,
                order_type: settingData.order_type,
                user_currency : Shopify.currency.active,
                custom_product: extraFee_data
            };
            payload['cart_data'] = JSON.stringify(cart_response.data);
            headers['authentication'] = security_token;
            let response = await axios.post(process.env.NODE_API, payload, { headers: headers });
            if (settingData?.order_type === '0' || settingData?.order_type === '1') {
                if (response && response.data.result == 'success' && response.data.invoice_url) {
                    setTimeout(() => {
                        window.location = response.data.invoice_url + '?' + 'locale=' + Shopify.locale;
                    }, 500);
                }
            } else if (settingData?.order_type === '2') {
                if (response && response.data.result == 'success') {
                    await axios.post('/cart/update.js', { attributes: { 'wholesale-hero-Revenue': JSON.stringify(prepared_data) } }, { 'headers': headers });
                    // let expirationDate = new Date();
                    // expirationDate.setTime(expirationDate.getTime() + (1 * 60 * 1000));
                    // let expires = 'expires=' + expirationDate.toUTCString();
                    // document.cookie = 'discountId' + '=' + response.data?.discount_id + ';' + expires + ';path=/';
                    LocalStorageSetData('discountId', response.data?.discount_id);
                    setTimeout(() => {
                        // window.location = '/checkout' + '?' + 'locale=' + Shopify.locale + response.data?.discount_code;
                        window.location.href = `${'https://' + window.location.hostname}/discount/${encodeURIComponent(response.data?.discount_code)}?redirect=/checkout&locale= ${Shopify.locale}`;
                    }, 500);
                }
            }
        }
    } catch (error) {
        console.log(error);
        window.location.href = CheckoutUrl;
    }
};

const getSpecificOffers = async (productIds, security_token, id) => {
    try {
        let customerId = meta.page && meta.page.customerId ? `${meta.page.customerId}` : '';
        let locationId = id ? `${id}` : '';
        let res = await ApiCall('POST', '/frontApi', { method_name: 'getSpecificOffers', products_id: productIds, customerId, locationId }, security_token);
        if (res && res.data && res.status == 200) {
            return res.data;
        } else {
            return {};
        }
    } catch (error) {
        return {};
    }
};

const getCollectionsPrice = async (productIds, security_token, id) => {
    try {
        let customerId = meta.page && meta.page.customerId ? `${meta.page.customerId}` : '';
        let locationId = id ? `${id}` : '';
        let res = await ApiCall('POST', '/frontApi', { method_name: 'getCollectionPageData', products_id: productIds, customerId, locationId }, security_token);
        if (res && res.data && res.status == 200) {
            return res.data;
        } else {
            return {};
        }
    } catch (error) {
        return {};
    }
};

const pdpNote = async (security_token) => {
    try {
        let customer_id = meta.page && meta.page.customerId ? `${meta.page.customerId}` : '';
        let res = await ApiCall('POST', '/frontApi', { method_name: 'getSpecificCustomers', customer_id }, security_token);
        if (res && res.data && res.status == 200) {
            return res.data;
        } else {
            return {};
        }
    } catch (error) {

    }
};

const frontErrorMsg = async (security_token, storename, errorMsg) => {
    try {
        let res = await ApiCall('POST', '/frontApi', { method_name: 'creatFrontLog', type: errorMsg, log: storename }, security_token);
        if (res && res.data && res.status == 200) {
            return res;
        } else {
            return {};
        }
    } catch (error) {
        console.log('error==============>', error);
    }
};

const getLocationData = async (security_token) => {
    try {
        let customerId = meta.page && meta.page.customerId ? `${meta.page.customerId}` : '';
        let res = await ApiCall('POST', '/frontApi', { method_name: 'getLocationsData', customerId }, security_token);
        if (res && res.data && res.status == 200) {
            return res.data;
        } else {
            return {};
        }
    } catch (error) {
        return {};
    }
};

const discountCodeRemoveApi = async (security_token, discount_id, shop_info_data) => {
    try {
      let payload = {
        method_name : 'discountCodeRemove',
        discount_id,
        shopInfo : shop_info_data
      };
      headers['authentication'] = security_token;
      await axios.post(process.env.NODE_API, payload, { headers: headers });
    } catch (error) {
        console.log('error---------', error);
    }
};

const frontSideDiscountCodeRemove = async () => {
    try {
        await axios.get(`http://${window.location.hostname}/checkout?discount=%20`);
    } catch (error) {
        console.log('error---------', error);
    }
};

const regFormApi = async (payload, security_token) => {
    try {
        headers['authentication'] = security_token;
        let res = await axios.post(`${process.env.NODE_BASE_URL}/frontApi`, payload, { headers: headers });
        if (res && res.data.message) {
            return res.data.message;
        } else {
            return {};
        }
    } catch (error) {
        if (error?.response?.data) {
            return error.response.data;
        }
    }
};

const regDataApi = async (path) => {
    try {
        let res = await axios.get(process.env.NODE_BASE_URL + path);
        if (res && res.data && res.status == 200) {
            return res.data;
        } else {
            return {};
        }
    } catch (error) {
        console.log('error=============>', error);
    }
};

const collectionPageError = async (headers,storename, apiMethodName) => {
    try {
        let res = await axios.post('/creatFrontLog', {method_name : 'creatFrontLog', type:'authentication key not found', log:storename, api_name:apiMethodName}, headers);
        if (res && res.data && res.status == 200) {
            return res;
        } else {
            return {};
        }
    } catch (error) {
       console.log('error==============>',error);
    }
};

const discountDataApi = async (product_data,productData,security_token) => {
    try {
        let headers = {
            'Content-Type': 'application/json',
            'authentication': `${security_token}`
        };
        if (product_data && isRealValue(productData) && security_token) {
            let productIds = [];
            for (let index = 0; index < productData.length; index++) {
                const productsid = productData[index];
                if (!productIds.includes(productsid.product_id.toString())) {
                    productIds.push(productsid.product_id.toString());
                }
            }
            let locationId = LocalStorageGetData('Location_id');
            locationId = locationId && isRealValue(locationId) && locationId;
            let res = await getSpecificOffers(productIds, security_token, locationId);
            if (res && res.status == 'SUCCESS' && isRealValue(res)) {
                // setResponse(res.data);
                return res;
            } else {
                return {};
            }
        }
    } catch (error) {
        console.log('error=========>', error);
    }
};

const extraFeeProductApi = async (productData) => {
    try {
            const payload = {
                items: productData.map(product => ({
                    id: product.id,
                    quantity: 1,
                    properties: {
                        'Extra fee for': product.propertity_name
                    }
                }))
            };
            let res = await axios.post(window.Shopify.routes.root + 'cart/add.js?sp=true', payload, { headers: headers });
            if (res.status === 200) {
                window.location.reload();
            }
    } catch (error) {
        console.log('------------->error', error);
    }
}

const extraFeeProductDeleteApi = (productData, product) => {
    try {
        productData?.forEach(async element => {
            const payload = {
                line: element + 1,
                quantity: 0
            }
            let res = await axios.post(window.Shopify.routes.root + 'cart/change.js?sp=true', payload, { headers: headers });
            if (res.status === 200) {
                if (isRealValue(product)) {
                    extraFeeProductApi(product);
                } else {
                    window.location.reload();
                }
            }
        });
    } catch (error) {
        console.log('------------->error', error);
    }
}

export { ApiCall, CartDataGet, DraftOder, getSpecificOffers, getCollectionsPrice, pdpNote, frontErrorMsg, getLocationData, discountCodeRemoveApi, regFormApi, frontSideDiscountCodeRemove, regDataApi, collectionPageError, discountDataApi, extraFeeProductApi, extraFeeProductDeleteApi };
