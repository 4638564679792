import React, { useEffect, useState } from 'react';
import PriceTable from './components/PriceTable';
import Cart from './components/Cart';
import CheckoutButton from './components/CheckoutButton';
import { ApiCall } from './helper/axios';
import { useDispatch, useSelector } from 'react-redux';
import { setShopData } from './redux/redux_slices/shop.slice';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import RegistrationForm from './components/RegistrationForm';
import { isRealValue } from './helper/Commanfun';

function App() {
  let [authenticat, setaAuthenticat] = useState(false);
  const { settingData } = useSelector((state) => state.shopInfo);
  let developerOption = settingData?.developer_option;
  let dataPares = developerOption ? JSON.parse(developerOption) : '';
  let root = document.getElementById('root');
  let extra_fee_product_data = settingData?.fees_and_free_shipping_status && settingData?.fees_and_free_shipping_status == 1 && isRealValue(settingData?.fees_product) ? JSON.parse(settingData?.fees_product) : null;
  if (meta.page.pageType == 'product' && extra_fee_product_data && extra_fee_product_data[meta.product.id]) {
    window.location.href = '/collections';
  }
  useEffect(() => {
    if (settingData) {
      let jsScriptData = dataPares.custom_js;
      let whoCusData = jsScriptData ? atob(jsScriptData) : '';
      (whoCusData ? window.eval(whoCusData) : '');
    }
  }, [settingData]);

  const dispatch = useDispatch();
  useEffect(() => {
    (async () => {
      try {
        let res = await ApiCall('POST', '/generate_token', { shop: Shopify.shop, api_call_type: 'front' }, {});
        let appStatus = res?.data?.data?.shop_info?.app_status;
        if (res && res.data && res.status == 200 && appStatus && appStatus == '1') {
          setaAuthenticat(true);
          dispatch(setShopData(res.data.data));
        }
      } catch (error) {
        console.log('error=========>', error);
      }
    })();
  }, []);
  return (
    <>
      <Routes>
        <Route path='/apps/wholesale' element={<RegistrationForm />} />
      </Routes>
      {authenticat && !root && <PriceTable />}
      {authenticat && !root && <Cart />}
      {authenticat && !root && <CheckoutButton />}
      {<style>{dataPares?.custom_css ? atob(dataPares?.custom_css) : ''}</style>}
    </>
  );

}

export default App;