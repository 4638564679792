import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CartDataGet } from '../../helper/axios';
import axios from 'axios';

const initialState = {
    product_data: [],
    discounts: 0,
    price_data: [],
    subtotal_discount_value : 0,
    extraFeeProductData : {},
};

export const CART = createAsyncThunk('cart', async () => {
    let cart_data = [];
    const response = await CartDataGet();
    return cart_data = response.data;
});
const cartInfo = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        discountsPrice(state, action) {
            state.discounts = action.payload;
        },
        pricetable(state, action) {
            state.price_data = action.payload;
        },
        subTotalDiscountValue(state, action) {
            state.subtotal_discount_value = action.payload;
        },
        extraFeeProduct(state, action) {
            state.extraFeeProductData = action.payload;
        }
    },

    extraReducers(builder) {
        builder
            .addCase(CART.pending, (state) => {

            })
            .addCase(CART.fulfilled, (state, action) => {
                const res = action.payload;
                state.product_data = res;
            })
            .addCase(CART.rejected, (state, action) => {
                state.cart_error = action.error;
            });
    }
});

export const { discountsPrice, pricetable, subTotalDiscountValue, extraFeeProduct } = cartInfo.actions;

export default cartInfo.reducer;