import React from 'react';
import './Table.css';
import { LocalStorageGetData, LocalStorageSetData, isRealValue } from '../helper/Commanfun';
import { useSelector } from 'react-redux';

function B2B(props) {
    const { shop_info_data, settingData } = useSelector((state) => state.shopInfo);
    let settingPopData = settingData?.location_popup_setting;
    let locationData = settingPopData ? JSON.parse(settingPopData) : '';
    let bodySelector = document.querySelector('body');
    let b2bData = LocalStorageGetData('Location_id');
    if (!(b2bData && isRealValue(b2bData))) {
        let addressArr = [];
        const createPopUp = () => {
            let popHtml = '';
            Object.values(props).map((locationData) => {
                for (let index = 0; index < locationData.length; index++) {
                    const element = locationData[index];
                    const shippingAddress = JSON.parse(element.shipping_address);
                    let obj = {
                        [element.id]: {
                            address1: shippingAddress.address1,
                            city: shippingAddress.city,
                            province: shippingAddress.province,
                            country: shippingAddress.country,
                            postal_code: shippingAddress.postal_code
                        }
                    };
                    addressArr.push(obj);
                    let fullAddress = `${shippingAddress.address1},${shippingAddress.city},${shippingAddress.province ? shippingAddress.province : ''} ${shippingAddress.country},${shippingAddress.postal_code}`;
                    popHtml += ` <label for="Location1" class="sp-modal-selection-content">
                    <input type="radio" class="sp-popup-radio" name="sp-radio" value=${element.id} />
            <div class="sp-modal-label-text">
            <p class="sp-address-text">${fullAddress}
        </p>
            </div>
        </label>`;
                }
            });
            bodySelector.style.overflow = 'hidden';
            return popHtml;
        };

        setTimeout(() => {
            if (isRealValue(document.querySelectorAll('.sp-modal-main-section'))) {
                let locationSelector = document.querySelectorAll('.sp-modal-label-text');
                document.querySelectorAll('.sp-popup-radio')[0].checked = true;
                document.querySelector('.sp-modal-select-btn').addEventListener('click', function () {
                    document.querySelector('.sp-modal-main-section').style.display = 'none';
                    let id = document.querySelector('input[name="sp-radio"]:checked').value;
                    for (let index = 0; index < addressArr.length; index++) {
                        const element = addressArr[index];
                        if (element[id]) {
                            LocalStorageSetData('Address', element[id]);
                        }
                    }
                    LocalStorageSetData('Location_id', id);
                    bodySelector.style.overflow = '';
                });
                for (let index = 0; index < locationSelector.length; index++) {
                    locationSelector[index].addEventListener('click', function () {
                        document.querySelectorAll('.sp-popup-radio')[index].checked = true;
                        document.querySelector('.sp-modal-select-btn').disabled = false;
                        if ( document.querySelector('.sp-btn-disabled')) {
                            document.querySelector('.sp-modal-select-btn').classList.remove('sp-btn-disabled');
                        }
                    });
                }
            }
            if (document.querySelector('.sp-search')) {
                document.querySelector('.sp-search').addEventListener('keyup', function () {
                    let inputValue = document.querySelector('.sp-search').value;
                    inputValue = inputValue.toLowerCase();
                    let textSelector = document.getElementsByClassName('sp-address-text');
                    for (let index = 0; index < textSelector.length; index++) {
                        if (!textSelector[index].innerHTML.toLowerCase().includes(inputValue)) {
                            document.querySelectorAll('.sp-modal-selection-content')[index].style.display = 'none';
                            document.querySelectorAll('.sp-modal-selection-content')[index].classList.add('no-search');
                        } else {
                            document.querySelectorAll('.sp-modal-selection-content')[index].style.display = null;
                            document.querySelectorAll('.sp-modal-selection-content')[index].classList.remove('no-search');
                        }
                    }
                    if (document.querySelectorAll('.sp-popup-radio')[0].checked && inputValue.length > 0) {
                        document.querySelectorAll('.sp-popup-radio')[0].checked = false;
                    } else if (!(document.querySelectorAll('input[name="sp-radio"]:checked').length) && inputValue.length == 0) {
                        document.querySelectorAll('.sp-popup-radio')[0].checked = true;
                    }
                    if (document.querySelectorAll('.sp-modal-selection-content:not(.no-search)').length) {
                        let valueChecked = document.querySelectorAll('.sp-modal-selection-content:not(.no-search) .sp-popup-radio:checked');
                        if (valueChecked.length) {
                        document.querySelector('.sp-modal-select-btn').disabled = false;
                        document.querySelector('.sp-modal-select-btn').classList.remove('sp-btn-disabled');
                       } else {
                        document.querySelector('.sp-modal-select-btn').disabled = true;
                        document.querySelector('.sp-modal-select-btn').classList.add('sp-btn-disabled');
                       }
                    }
                    if (document.querySelectorAll('.sp-modal-selection-content:not(.no-search)').length == 0) {
                        if (!document.querySelector('.sp-data')) {
                            document.querySelector('.sp-modal-select-btn').disabled = true;
                            document.querySelector('.sp-modal-select-btn').classList.add('sp-btn-disabled');
                            let nodata = '<p class="sp-data" style="text-align:center; margin:auto; color:#6D7175; font-size:25px; font-weight:bold">No location found!!</p>';
                            document.querySelector('.sp-modal-selection-section.modal-selection-body').insertAdjacentHTML('beforeend', nodata);
                        }
                    } else {
                        let notFountSelector = document.querySelector('.sp-data');
                        if (notFountSelector) {
                            notFountSelector.remove();
                        }
                    }
                });
            }
        }, 1);

        let popup = `<section class="sp-modal-main-section">
        <div class="sp-modal-container">
            <div class="sp-modal-main-content">
                <div class="sp-modal-main-header">
                <h2 class="sp-modal-top-name">${shop_info_data?.shop_name}</h2>
                <h1 class="sp-modal-top-title">${locationData?.heading}</h2>
                    <p>${locationData?.sub_heading_text}</p>
                </div>
                 <div class="sp-modal-main-body">
                     <form class="sp-modal-selection-section">
                        <div class="sp-modal-search-input">
                            <input type="text" id="search" class="sp-search" placeholder="Search for a Location" name="search">
                            <div class="sp-modal-search-svg">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M8 12C5.791 12 4 10.209 4 8C4 5.791 5.791 4 8 4C10.209 4 12 5.791 12 8C12 10.209 10.209 12 8 12ZM17.707 16.293L12.887 11.473C13.585 10.492 14 9.296 14 8C14 4.687 11.313 2 8 2C4.687 2 2 4.687 2 8C2 11.313 4.687 14 8 14C9.296 14 10.492 13.585 11.473 12.887L16.293 17.707C16.488 17.902 16.744 18 17 18C17.256 18 17.512 17.902 17.707 17.707C18.098 17.316 18.098 16.684 17.707 16.293Z" fill="#5C5F62"/>
                            </svg>
                            </div>
                        </div>
                    </form>
                     <form class="sp-modal-selection-section modal-selection-body">
                       ${createPopUp()}
                    </form>
                </div>
               <div class="sp-modal-main-footer">
                    <button type="button" class="sp-modal-select-btn">${locationData?.btn_text}</button>
                </div>
            </div>
        </div>
    </section>`;

        { bodySelector.insertAdjacentHTML('afterbegin', popup); }
    }
    return (
        <>
        <style>
            .sp-modal-main-footer .sp-modal-select-btn {'{'}
            background-color : {locationData?.btn_bg_color};
            border: 1px solid {locationData?.btn_bg_color};
            color : {locationData?.btn_text_color}
            {'}'}
            .sp-modal-main-section .sp-modal-main-header p {'{'}
            color : {locationData?.sub_heading_color}
            {'}'}
            .sp-modal-top-title {'{'}
            color : {locationData?.tbl_heading_color}
            {'}'}
        </style>
        </>
    );
}

export default B2B;