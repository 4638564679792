import React from 'react';
import { DraftOder } from '../helper/axios';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { LocalStorageGetData, getAttribute, isRealValue, productDiscount } from '../helper/Commanfun';
import { CART } from '../redux/redux_slices/cart.slice';

function CheckoutButton() {
  const { discounts, price_data, subtotal_discount_value, extraFeeProductData } = useSelector((state) => state.cartInfo);
  const { shop_info_data, security_token, settingData } = useSelector((state) => state.shopInfo);
  const dispatch = useDispatch();
  const orderSettingData = JSON.parse(settingData?.order_setting);
  let extra_fee_product_data = settingData?.fees_and_free_shipping_status && settingData?.fees_and_free_shipping_status == 1 && isRealValue(settingData?.fees_product) ? JSON.parse(settingData?.fees_product) : null;
  let developerOption = settingData?.developer_option ? JSON.parse(settingData?.developer_option) : null;
  let ChkSelector = ["[name='checkout']",
    "[href='/checkout']",
    "form[action='/checkout'] input[type='submit']",
    "input[name='checkout']",
    "button[name='checkout']",
    'button.new_checkout_button',
    "input[value='Checkout']",
    "input[value='Check out']",
    "a[href='/checkout']", "input[name='goto_pp']",
    'button.additional-checkout-button',
    'div.additional-checkout-button',
    '#paypal-express-button',
    '.additional-checkout-button--apple-pay',
    'button.icartCheckoutBtn',
    "a[href*='" + window.location.hostname + "/checkout']",
    "[name='icartCheckout']",
    '#corner-cowi-cart-summary-card-cta-button'
  ];

  if (developerOption?.checkout_button_selector.trim()) {
    ChkSelector.unshift(developerOption?.checkout_button_selector)
  }

  addCustomEventListener(ChkSelector, 'click', function (e) {
    if (e) {
      e?.target ? e.target.setAttribute('disabled', 'disabled') : e.target.setAttribute('disabled', '');
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      window.stop();
      specificCheckout();
    }
  });

  function serialize(form) {
    let element_obj = '';
    if (!form || form.nodeName !== 'FORM') {
      element_obj = form.querySelectorAll('select, input, textarea');
    } else {
      element_obj = form.elements;
    }
    let i, j;
    let q = [];
    for (i = element_obj.length - 1; i >= 0; i = i - 1) {
      if (element_obj[i].name === '') {
        continue;
      }
      switch (element_obj[i].nodeName) {
        case 'INPUT':
          switch (element_obj[i].type) {
            case 'text':
            case 'tel':
            case 'email':
            case 'hidden':
            case 'password':
            case 'button':
            case 'reset':
            case 'submit':
            case 'number':
              q.push(element_obj[i].name + '=' + encodeURIComponent(element_obj[i].value));
              break;
            case 'checkbox':
            case 'radio':
              if (element_obj[i].checked) {
                q.push(element_obj[i].name + '=' + encodeURIComponent(element_obj[i].value));
              }
              break;
          }
          break;
        case 'file':
          break;
        case 'TEXTAREA':
          q.push(element_obj[i].name + '=' + encodeURIComponent(element_obj[i].value));
          break;
        case 'SELECT':
          switch (element_obj[i].type) {
            case 'select-one':
              q.push(element_obj[i].name + '=' + encodeURIComponent(element_obj[i].value));
              break;
            case 'select-multiple':
              for (j = element_obj[i].options.length - 1; j >= 0; j = j - 1) {
                if (element_obj[i].options[j].selected) {
                  q.push(element_obj[i].name + '=' + encodeURIComponent(element_obj[i].options[j].value));
                }
              }
              break;
          }
          break;
        case 'BUTTON':
          switch (element_obj[i].type) {
            case 'reset':
            case 'submit':
            case 'button':
              q.push(element_obj[i].name + '=' + encodeURIComponent(element_obj[i].value));
              break;
          }
          break;
      }
    }
    return q.join('&');
  }

  async function ActBtnProductAdd(form) {
    try {
      const get_serialize_data = serialize(form);
      const act_header = { 'Content-type': 'application/x-www-form-urlencoded; charset=UTF-8', 'x-requested-with': 'XMLHttpRequest' };
      const response = await axios.post('/cart/add.js?ref_wholesale=true', get_serialize_data, { 'headers': act_header });
      return response;
    } catch (error) {
      console.log('eee------->'.error);
      return error;
    }
  }

  const IcartAddToCartProduct = async (ATCFormClose, html_data) => {
    try {
      if (ATCFormClose) {
        const btn_text = html_data.innerHTML;
        html_data.setAttribute('disabled', 'disabled');
        const cartRes = await axios.get('/cart/clear?ref_wholesale=true');
        if (cartRes && cartRes.status == 200) {
          const response = await ActBtnProductAdd(ATCFormClose);
          if (response && response.status == 200) {
            html_data.removeAttribute('disabled');
            buyNowDraft(response.data);
          } else if (response.data.status == 422) {
            if (response && response.data.description) {

            }
            html_data.innerHTML = btn_text;
            html_data.setAttribute('disabled', 'disabled');
          } else {
            html_data.innerHTML = btn_text;
            html_data.setAttribute('disabled', 'disabled');
            dispatch(CART());
          }
        }
      }
    } catch (error) {
      console.log('error----------->', error);
    }
  };

  let buyNowCheckout = ['button[data-testid="Checkout-button"]', 'input[data-testid="Checkout-button"]', 'button[name$=checkout]', 'input[name$=checkout]', '.shopify-payment-button__button'];

  addCustomEventListener(buyNowCheckout, 'click', function (e) {
    if (e) {
      let html_data = e.target;
      if (html_data) {
        let ATCFormClose = '';
        ATCFormClose = html_data.closest("form[action*='/cart/add']");
        if (html_data) {
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
          /*  let btn_text = html_data.innerText;*/
          IcartAddToCartProduct(ATCFormClose, html_data);
        }
      }
    }
  });

  window.addEventListener('pageshow', function (event) {
    let pagehistory = event.persisted ||
      (typeof window.performance != 'undefined' &&
        window.performance.navigation.type === 2);
    if (pagehistory) {
      let checkoutBtnDisable = document.querySelectorAll(ChkSelector);
      for (let index = 0; index < checkoutBtnDisable.length; index++) {
        checkoutBtnDisable[index].disabled = '';
      }
    }
  });

  window.specificCheckout = () => {
    let AddressGet = LocalStorageGetData('Address');
    let customer = [];
    let logincus = [];
    let nonlogin = [];
    let finalPriceData = [];

    Object.keys(price_data).length && Object.keys(price_data).map((data) => {
      let resData = price_data[data];
      if (isRealValue(resData)) {
        resData?.map((item) => {
          let exclusive_Inclusive = item.customer_allow_type == 1 ? !(item.selected_user_value?.includes(meta?.page?.customerId?.toString())) : item.selected_user_value?.includes(meta?.page?.customerId?.toString());
          if (item.selected_user_value && exclusive_Inclusive && item.selected_user_type == 2) {
            customer.push(item);
          } else if (item.selected_user_type == 1) {
            logincus.push(item);
          } else if (item.selected_user_type == 0) {
            nonlogin.push(item);
          } else if (item.selected_user_type === null) {
            customer.push(item);
          }
        });
      }
    });

    if (meta.page.customerId) {
      if (customer.length || logincus.length) {
        if (customer.length && logincus.length) {
          let cusDiscountData = [...logincus];
          for (let index = 0; index < customer.length; index++) {
            let SpecificloginCus = customer[index];
            for (let i = 0; i < logincus.length; i++) {
              const element = logincus[i];
              if (Object.keys(SpecificloginCus).toString() == Object.keys(element).toString()) {
                logincus.splice(i, 1);
              }
            }
          }
          finalPriceData = [...customer, ...cusDiscountData];
        } else if (customer.length) {
          finalPriceData = customer;
        } else {
          finalPriceData = logincus;
        }
      }
    } else if (nonlogin.length > 0) {
      nonlogin.map((nonlogincusitem) => {
        finalPriceData.push(nonlogincusitem);
      });
    }

    if (isRealValue(extraFeeProductData) || (finalPriceData.length && discounts > 0)) {
      finalPriceData.sort((a, b) => a.priority - b.priority);
      DraftOder(discounts, finalPriceData, shop_info_data, security_token, AddressGet, orderSettingData, subtotal_discount_value, extraFeeProductData, extra_fee_product_data);
    } else {
      window.location.href = 'https://' + window.location.hostname + '/checkout';
    }
  };

  window.buyNowDraft = async (product) => {
    try {
      // let AddressGet = LocalStorageGetData('Address');
      let customer = [];
      let logincus = [];
      let nonlogin = [];
      let finalPriceData = [];

      Object.keys(price_data).length && Object.keys(price_data).map((data) => {
        let resData = price_data[data];
        if (isRealValue(resData)) {
          resData?.map((item) => {
            let exclusive_Inclusive = item.customer_allow_type == 1 ? !(item.selected_user_value?.includes(meta?.page?.customerId?.toString())) : item.selected_user_value?.includes(meta?.page?.customerId?.toString());
            if (item.selected_user_value && exclusive_Inclusive && item.selected_user_type == 2) {
              customer.push(item);
            } else if (item.selected_user_type == 1) {
              logincus.push(item);
            } else if (item.selected_user_type == 0) {
              nonlogin.push(item);
            } else if (item.selected_user_type === null) {
              customer.push(item);
            }
          });
        }
      });

      if (meta.page.customerId) {
        if (customer.length || logincus.length) {
          if (customer.length && logincus.length) {
            let cusDiscountData = [...logincus];
            for (let index = 0; index < customer.length; index++) {
              let SpecificloginCus = customer[index];
              for (let i = 0; i < logincus.length; i++) {
                const element = logincus[i];
                if (Object.keys(SpecificloginCus).toString() == Object.keys(element).toString()) {
                  logincus.splice(i, 1);
                }
              }
            }
            finalPriceData = [...customer, ...cusDiscountData];
          } else if (customer.length) {
            finalPriceData = customer;
          } else {
            finalPriceData = logincus;
          }
        }
      } else if (nonlogin.length > 0) {
        nonlogin.map((nonlogincusitem) => {
          finalPriceData.push(nonlogincusitem);
        });
      }

      let CheckoutUrl = 'https://' + window.location.hostname + '/checkout';
      if (finalPriceData.length) {
        let prepared_data = {};
        let subTotal = product.original_line_price / 100;
        let original_subTotal = subTotal;
        let minMaxDiscountValue = true;
        let cartCount = 1;
        cartCount -= 1;
        let productVariant = [];
        let priceLine = product.line_price / 100;
        let lineItem = product.line_price / 100;
        let discountType = null;
        let discountAmount;
        let slabId = [];
        for (let index = 0; index < finalPriceData.length; index++) {
          let tableDataVariantId = finalPriceData[index];
          let productVariantId = tableDataVariantId.apply_to_value ? JSON.parse(tableDataVariantId.apply_to_value) : '';
          let discountProductVariantId = [].concat(...Object.values(productVariantId));
          let offerValue = JSON.parse(tableDataVariantId.offer_value);
          let cartValueMinMax = JSON.parse(tableDataVariantId.cart_value);
          if (!(productVariant.includes(product.id))) {
            if (tableDataVariantId?.catalogData?.length) {
              for (let index = 0; index < tableDataVariantId.catalogData.length; index++) {
                const element = tableDataVariantId.catalogData[index];
                let productData = JSON.parse(element.products);
                for (const productkey in productData) {
                  let variantData = productData[productkey];
                  for (const varaiantId in variantData) {
                    if (varaiantId == product.id && !(slabId.includes(product.id))) {
                      slabId.push(product.id);
                      let discountData = variantData[varaiantId];
                      discountProductVariantId.push(varaiantId);
                      discountAmount = isRealValue(discountData) && productDiscount(product.quantity, discountData.slab, 'true', discountData.volume_pricing, product.price / 100, true, tableDataVariantId?.offer_type);
                      discountType = discountData.volume_pricing;
                    }
                  }
                }
              }
            }
            // Object.values(productVariantId).map((id) => {
            let exclusive_Inclusive = tableDataVariantId.product_allow_type == 1 ? !(discountProductVariantId.includes(product.id.toString())) : discountProductVariantId.includes(product.id.toString());
            if (exclusive_Inclusive) {
              productVariant.push(product.id);
              let temp = [];
              if (tableDataVariantId?.offer_type == 0 || tableDataVariantId?.offer_type == 1) {
                discountAmount = productDiscount(product.quantity, offerValue, 'false', tableDataVariantId.discount_type, product.price / 100, true, tableDataVariantId?.offer_type);
              }
              if ((discountAmount || discountAmount === 0) && lineItem > 0) {
                let finalPrice = original_subTotal;
                let minCartValue = cartValueMinMax.Min != '' ? cartValueMinMax.Min : finalPrice;
                let maxCartValue = cartValueMinMax.Max != '' ? cartValueMinMax.Max : finalPrice;
                minMaxDiscountValue = finalPrice >= minCartValue && finalPrice <= maxCartValue;
                if (tableDataVariantId.discount_type == 0 || discountType == 0) {
                  discountAmount = discountAmount <= 100 ? priceLine * discountAmount / 100 : 0;
                }
                if (tableDataVariantId.discount_type == 1 || discountType == 1) {
                  discountAmount = discountAmount <= priceLine ? priceLine - discountAmount : false;
                }
                if (tableDataVariantId.discount_type == 2 || discountType == 2) {
                  discountAmount = discountAmount <= priceLine ? discountAmount : false;
                }
                discountAmount = minMaxDiscountValue ? discountAmount : false;
                if ((discountAmount || discountAmount === 0) && minMaxDiscountValue) {
                  let prepared_obj = {
                    product_id: product.product_id,
                    variant_id: product.variant_id,
                    discount_value: discountAmount,
                    product_quantity: product.quantity,
                    offer_type: tableDataVariantId.offer_type,
                    offer_id: tableDataVariantId.id,
                    discount_title: tableDataVariantId.offer_name,
                    discount_type: "0"
                  };
                  temp.push(prepared_obj);
                  prepared_data[cartCount] = temp;
                  original_subTotal = tableDataVariantId.discount_type == 1 || discountType == 1 ? finalPrice - (priceLine - discountAmount) : finalPrice - discountAmount;
                }
              } else if (discountAmount && lineItem == 0) {
                let bogoDiscountPrice = product.original_line_price / 100;
                let finalBogoDiscount = bogoDiscountPrice - lineItem;
                let prepared_obj = {
                  product_id: product.product_id,
                  variant_id: product.variant_id,
                  discount_value: finalBogoDiscount,
                  product_quantity: product.quantity
                };
                temp.push(prepared_obj);
                prepared_data[cartCount] = temp;
              }
            }
            // });
          }

          if (tableDataVariantId.apply_to_value === null && tableDataVariantId.apply_to_type == 0 && !(productVariant.includes(product.id))) {
            let temp = [];
            let discountAmount = productDiscount(product.quantity, offerValue, 'false', tableDataVariantId.discount_type, product.price / 100, true);
            if (discountAmount && lineItem > 0) {
              let finalPrice = original_subTotal;
              let minCartValue = cartValueMinMax.Min != '' ? cartValueMinMax.Min : finalPrice;
              let maxCartValue = cartValueMinMax.Max != '' ? cartValueMinMax.Max : finalPrice;
              minMaxDiscountValue = finalPrice >= minCartValue && finalPrice <= maxCartValue;
              if (tableDataVariantId.discount_type == 0) {
                discountAmount = discountAmount <= 100 ? priceLine * discountAmount / 100 : 0;
              }
              if (tableDataVariantId.discount_type == 1) {
                discountAmount = discountAmount <= priceLine ? priceLine - discountAmount : 0;
              }
              if (tableDataVariantId.discount_type == 2) {
                discountAmount = discountAmount <= priceLine ? discountAmount : 0;
              }
              discountAmount = minMaxDiscountValue ? discountAmount : 0;
              if (discountAmount && minMaxDiscountValue) {
                let prepared_obj = {
                  product_id: product.product_id,
                  variant_id: product.variant_id,
                  discount_value: discountAmount,
                  product_quantity: product.quantity,
                  offer_type: tableDataVariantId.offer_type,
                  offer_id: tableDataVariantId.id,
                  discount_title: tableDataVariantId.offer_name,
                  discount_type: "0"
                };
                temp.push(prepared_obj);
                prepared_data[cartCount] = temp;
                original_subTotal = tableDataVariantId.discount_type == 1 ? finalPrice - (priceLine - discountAmount) : finalPrice - discountAmount;
              }
            } else if (discountAmount && lineItem == 0) {
              let bogoDiscountPrice = product.original_line_price / 100;
              let finalBogoDiscount = bogoDiscountPrice - lineItem;
              let prepared_obj = {
                product_id: product.product_id,
                variant_id: product.variant_id,
                discount_value: finalBogoDiscount,
                product_quantity: product.quantity
              };
              temp.push(prepared_obj);
              prepared_data[cartCount] = temp;
            }
          }
        }
        cartCount = cartCount - 1;
        if (prepared_data && Object.keys(prepared_data).length) {
          const noteAttribute = getAttribute();
          const noteAttr = [];
          if (isRealValue(noteAttribute) && Object.keys(noteAttribute).length) {
            for (let attrKey in noteAttribute) {
              noteAttribute[attrKey] && noteAttr.push({ 'name': attrKey, 'value': noteAttribute[attrKey] });
            }
          }
          let orderNote = document.querySelector('[name="note"]');
          orderNote = orderNote ? orderNote.value : '';
          let payload = {
            attribute: prepared_data,
            method_name: 'createDraft',
            shopInfo: shop_info_data,
            note_attribute: noteAttr,
            order_note: orderNote
          };
          let abc = { 'items': [product] };
          payload['cart_data'] = JSON.stringify(abc);
          let headers = {
            'Content-Type': 'application/json',
            'authentication': `${security_token}`
          };
          let response = await axios.post(process.env.NODE_API, payload, { headers: headers });
          if (response && response.data.result == 'success' && response.data.invoice_url) {
            setTimeout(() => {
              window.location = response.data.invoice_url + '?' + 'locale=' + Shopify.locale;
            }, 500);
          } else {
            window.location.href = CheckoutUrl;
          }
        } else {
          window.location.href = CheckoutUrl;
        }
      } else {
        window.location.href = CheckoutUrl;
      }
    } catch (error) {
      console.log('error======>', error);
      window.location.href = 'https://' + window.location.hostname + '/checkout';
    }
  };

  return (
    <div>

    </div>
  );
}

export default CheckoutButton;