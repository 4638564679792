import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import { Provider } from 'react-redux';
import store from './redux/store';
import ReactDOM from 'react-dom';
window.specificBunLoad = true;
window.addCustomEventListener = (selector, event, handler) => {
  let rootElement = document.querySelector('body');
  let bodyInter = setInterval(() => {
    if(rootElement){
      clearInterval(bodyInter);
      rootElement.addEventListener(event, function (evt) {
        let targetElement = evt.target;
        while (targetElement != null) {
          if (targetElement.matches(selector)) {
            handler(evt);
            return;
          }
          targetElement = targetElement.parentElement;
        }
      },
        true
      );
    }
  }, 50);
};

let rootIdGet = document.getElementById('root');
let specifiContain = document.getElementsByClassName('specific_block');
if((specifiContain && specifiContain.length) || rootIdGet){
  // const root = rootIdGet ? ReactDOM.createRoot(rootIdGet) : createRoot(specifiContain[0]);
  const rootElement = rootIdGet || specifiContain[0];
  const root = createRoot(rootElement);
  root.render(
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
  );
}

if (window.fetch) {
  const nativeFetch = window.fetch;
  window.fetch = async function () {
    const response = await nativeFetch(...arguments);
    if (response && response.status == 200 && (response.url.includes('/change') || (response.url.includes('/add.js')) || (response.url.includes('/add'))) || (response.url.includes('/update.js')) && arguments && arguments.length > 0) {
      getFetchCall();
    }
    return response;
  };
}

// (function () {
//   window.XMLHttpRequest = new Proxy(window.XMLHttpRequest, {
//     construct(target, args) {
//       const realXHR = new target(...args);
//       realXHR.addEventListener('readystatechange', function (e) {
//         let ajaxUrl = typeof realXHR._url != 'undefined' ? realXHR._url : realXHR.responseURL;
//         if (realXHR.readyState == 4 && realXHR.status == 200 && (typeof realXHR._url != 'undefined' || typeof realXHR.responseURL != 'undefined')) {
//           if (ajaxUrl === '/cart/change' || ajaxUrl === '/cart/add.js' || ajaxUrl === '/add') {
//             getFetchCall();
//           }
//         }
//       }, false);
//       return realXHR;
//     }
//   });
// })();

(function () {
  window.XMLHttpRequest = new Proxy(window.XMLHttpRequest, {
    construct(target, args) {
      const realXHR = new target(...args);
      realXHR.addEventListener('readystatechange', function (e) {
        let ajaxUrl = typeof realXHR._url != 'undefined' ? realXHR._url : realXHR.responseURL;
        if (realXHR.readyState == 4 && realXHR.status == 200 && (typeof realXHR._url != 'undefined' || typeof realXHR.responseURL != 'undefined')) {
            if (ajaxUrl === '/cart/change' || ajaxUrl === '/cart/add.js' || ajaxUrl === '/add') {
              getFetchCall();
            }
        }
      }, false);
      return realXHR;
    }
  });
})();
